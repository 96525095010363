export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const ADD_PRODUCT_TO_CART_FAILURE = 'ADD_PRODUCT_TO_CART_FAILURE';
export const UPDATE_PROFILE_INFORMATION_SUCCESS = 'UPDATE_PROFILE_INFORMATION_SUCCESS';
export const UPDATE_PROFILE_INFORMATION_FAILURE = 'UPDATE_PROFILE_INFORMATION_FAILURE';
export const UPDATE_PROFILE_CHILDREN_SUCCESS = 'UPDATE_PROFILE_CHILDREN_SUCCESS';
export const UPDATE_PROFILE_CHILDREN_FAILURE = 'UPDATE_PROFILE_CHILDREN_FAILURE';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const CHECKOUT_CARD = 'CHECKOUT_CARD';
export const CARDS_BY_PRODUCT = 'CARDS_BY_PRODUCT';
