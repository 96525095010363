import React from 'react';
import PropTypes from 'prop-types';
import { priceNoZeros } from 'helpers/priceNoZeros';
import { discount } from 'helpers/discount';
import FactureHeader from 'components/Facture/FactureHeader';
import FactureFooter from 'components/Facture/FactureFooter';
import { useSelector } from 'react-redux';

const DevisContent = ({ factureRef, cards, isDevi, tabHeader }) => {
  const user = useSelector(({ auth }) => auth?.user);
  const tvaList = user?.tvaList;

  return (
    <div size="A4" className="factureContainer py-9 factureInfo">
      <div className="ml-5 mr-5 mt-3" ref={factureRef}>
        <FactureHeader cards={cards?.cards} isDevi={isDevi} />
        <div>
          <table cellSpacing={3} cellPadding={5} className="tableFix" rules="all">
            <>
              <thead>{tabHeader()}</thead>
              {cards &&
                cards.cards.map(c => {
                  return (
                    <tbody key={c?.id}>
                      <tr>
                        <td style={{ wordBreak: 'break-word' }} className="text-left code">
                          {c?.product?.reference}
                        </td>
                        <td style={{ wordBreak: 'break-word' }} className="text-left code">
                          {c?.product?.SAREF1}
                        </td>

                        <td className="text-left">{c?.product?.title}</td>
                        <td style={{ wordBreak: 'break-word' }} className="text-right qte">
                          {c?.quantity}
                        </td>
                        <td style={{ wordBreak: 'break-word' }} className="text-right price">
                          {priceNoZeros(c?.product?.price, 'TND', true)}
                        </td>
                        <td>{discount(c.product.articlesDiscount)}</td>
                        <td style={{ wordBreak: 'break-word' }} className="text-right ">
                          {discount(c.product.articlesDiscount) !== 0 ? (
                            <>
                              {`${priceNoZeros(
                                (c?.product?.price - (c?.product?.price * discount(c.product.articlesDiscount)) / 100) *
                                  c?.quantity,
                                'TND',
                                true,
                              )}`}
                            </>
                          ) : (
                            <>{`${priceNoZeros(c?.product?.price * c?.quantity, 'TND', true)}`}</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </>
          </table>
        </div>

        <FactureFooter tvaList={tvaList} cards={cards} isDevi={isDevi} />
      </div>
    </div>
  );
};

DevisContent.propTypes = {
  factureRef: PropTypes.any,
  cards: PropTypes.any,
  isDevi: PropTypes.bool,
  tabHeader: PropTypes.func,
};
export default DevisContent;
