/* eslint-disable max-lines */
import React, { useState } from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCart } from '_redux/actions/user';
import QuantityInputCarts from 'components/QuantityInputCarts';
import Modal from 'components/Modal';
import { ModalBody } from 'reactstrap';
import { isEmpty } from 'helpers/isEmptyObject';
import Devis from 'components/Devis';
import { quantityInCart } from 'helpers/quantityInCarts';
import { discount } from 'helpers/discount';
import { priceNoZeros } from 'helpers/priceNoZeros';
import { CircularProgress } from '@material-ui/core';
import { totalOfCarts } from 'helpers/totalOfCarts';
import { getTtcPrice } from 'helpers/getTtcPrice';

const CartItem = ({ shopName, expanded, cards, allCards, user }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const isLoading = useSelector(({ loading }) => loading.loading);

  return (
    <div>
      {expanded === shopName && (
        <>
          {!isEmpty(cards) ? (
            <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-4">
              <h1 className="h3 mb-0 my-auto">{shopName}</h1>
              <div className="pull-right">
                <button
                  className="btn btn-success btn-pill transition-3d-hover text-white ml-1 mb-1"
                  type="button"
                  onClick={() => setOpenModal(true)}
                >
                  Demande un Devis
                </button>
              </div>
            </div>
          ) : (
            <div>le panier de ce boutique est vide</div>
          )}

          <Modal
            className="myModal"
            isOpen={openModal}
            closeModal={() => setOpenModal(false)}
            title={<span>{`Demande un devis pour ${shopName}`}</span>}
          >
            <ModalBody>
              <Devis shopname={shopName} cards={cards} />
            </ModalBody>
          </Modal>

          {cards.map(cart => {
            return (
              <div key={cart?.product?.eanId} id="shopCartAccordion" className="accordion card shadow-soft mb-4">
                <div className="d-flex align-items-center my-2">
                  <div className="media-body">
                    <div style={{ padding: 10 }} className="row col-md-12">
                      <div className="col-md-2 my-auto">
                        <ImageAsyncComponent height="20" image={getImageUrl(cart?.productEanId)} />
                      </div>
                      <div className="col-md-4 my-auto">
                        <div className="h8 text-center">{cart?.product?.title}</div>
                      </div>

                      <div className="col-md-2 my-auto text-center">
                        {discount(cart.product.articlesDiscount) !== 0 ? (
                          <>
                            <del>{priceNoZeros(cart?.product?.price, 'TND')}</del>
                            <h5>
                              {priceNoZeros(
                                cart?.product?.price -
                                  (cart?.product?.price * discount(cart.product.articlesDiscount)) / 100,
                                'TND',
                              )}
                            </h5>
                          </>
                        ) : (
                          <h5>{formatPrice(cart?.product?.price, 'TND')}</h5>
                        )}
                      </div>
                      <div className="col-md-2 my-auto">
                        {isLoading ? (
                          <div className="d-flex justify-content-center">
                            <CircularProgress size={50} color="inherit" className="my-auto" disableShrink />
                          </div>
                        ) : (
                          <QuantityInputCarts
                            productQuantity={cart?.product?.quantity}
                            qteInCard={quantityInCart(allCards, cart?.product?.eanId)}
                            productIdRef={cart?.productRef}
                            card={cart}
                            shopAdress={shopName}
                          />
                        )}
                      </div>
                      <div className="col-md-2 my-auto d-flex justify-content-center">
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(deleteCart(cart.id));
                          }}
                          style={{ borderRadius: 5, border: 0 }}
                          title="panier"
                          to="/card"
                          className="js-hs-unfold-invoker btn-icon btn-ghost-secondary"
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="d-flex justify-content-end">
            <div className="accordion card shadow-soft mb-4 col-sm-4">
              <div className="d-flex align-items-center my-2">
                <div className="media-body">
                  <div style={{ padding: 10 }} className="row col-md-12">
                    <div className="col-md-12 my-auto">
                      <div className="h8 row justify-content-lg-between">
                        <div>
                          <b>Total (HT):</b>
                        </div>
                        <div>
                          <p>{priceNoZeros(totalOfCarts(cards), 'TND')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 my-auto">
                      <div className="h8 row justify-content-lg-between">
                        <b>Total (TTC):</b>
                        <p>{getTtcPrice(cards, user?.tvaList, user?.user?.user)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Link
                      to={`/checkout/${shopName}`}
                      className="btn btn-primary btn-pill transition-3d-hover text-white mb-1"
                    >
                      Commander
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

CartItem.propTypes = {
  shopName: PropTypes.string,
  cards: PropTypes.array,
  user: PropTypes.object,
  allCards: PropTypes.array,
  expanded: PropTypes.string.isRequired,
};
export default CartItem;
