const MB_INFO = {
  address: '01, Rue Abdelhafidh El Mekki',
  state: 'Complexe municipal La Marsa 2070',
  city: 'TUNIS',
  matFisc: 'Matricule Fiscale: 1285005R/A/000',
  phone: '71 854 750',
  rib: 'RIB: 21025025404700005606',
  RC: 'RC: 56699989996',
  EMAIL: 'contact@mbdistribution.tn',
  BANK: 'Banque: TSB',
};

export default MB_INFO;
