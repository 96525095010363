const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const priceNoZeros = (number, currency, noCurrency) => {
  number = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  })
    .format(number)
    .replace(/(\.|,)00$/g);
  const localCurrencySymbol = getCurrencySymbol('en-GB', 'TND');
  const CurrencySymbolNeedle = new RegExp(localCurrencySymbol, 'g');

  let finalNumber = 0;

  noCurrency ? finalNumber = number.replace(CurrencySymbolNeedle, '').replace(/\s+/g, '') : finalNumber = number;

  return finalNumber;
};
