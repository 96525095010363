import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';

const CreateUserForm = ({ handleSubmit }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">Création Compte User MB</h1>
      </div>
      <br />
      <InputField type="tiers" name="tiers" label="Tiers" placeholder="C00..." validate={[Validators.required]} />

      <InputField
        type="password"
        name="password"
        label="Mot de passe"
        placeholder="********"
        forgotPasswordLink="/"
        validate={[Validators.required]}
      />

      <div className="row align-items-center mb-5">
        <div className="col-12 d-flex justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            Creation
          </button>
        </div>
      </div>
    </form>
  );
};

CreateUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'createUserForm' })(CreateUserForm);
