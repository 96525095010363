/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { Radio, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import 'react-dates/initialize';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const SearchBannerEnt = ({
  setSearchValue,
  searchValue,
  filterByType,
  setFilterByType,
  date,
  focusedInput,
  setFocusedInput,
  setDate,
  isFacture,
}) => {
  const clearFilter = () => {
    setSearchValue('');
    setFilterByType('');
    setDate({ startDate: null, endDate: null });
  };
  const handleFilterByTypeChange = e => {
    setFilterByType(e.target.value);
  };

  const handleSearchInputChanges = e => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            value={searchValue}
            onChange={handleSearchInputChanges}
            placeholder="Numéro pièce"
          />
        </div>

        <div className="col-md-4">
          <div className="datePicker-searchBar">
            <DateRangePicker
              displayFormat="DD-MM-YYYY"
              startDateId="startDate"
              endDateId="endDate"
              startDatePlaceholderText="De"
              endDatePlaceholderText="à"
              startDate={date.startDate}
              endDate={date.endDate}
              onDatesChange={({ startDate, endDate }) => {
                setDate({ startDate, endDate });
              }}
              isOutsideRange={day => !day}
              onFocusChange={val => {
                setFocusedInput(val);
              }}
              focusedInput={focusedInput}
              customArrowIcon={<></>}
              hideKeyboardShortcutsPanel
            />
          </div>
        </div>
        {!isFacture && (
          <div className="col-md-3">
            <span>Tous :</span>
            <Radio
              checked={filterByType === ''}
              onChange={handleFilterByTypeChange}
              value=""
              name="radio-button-demo"
              label="Tous"
              style={{ marginBottom: 10 }}
            />
            <span>Actif :</span>
            <GreenRadio
              checked={filterByType === '1'}
              onChange={handleFilterByTypeChange}
              value="1"
              label="Actif"
              name="radio-button-demo"
              style={{ marginBottom: 10 }}
            />
          </div>
        )}
        <div className="col-md-2">
          <button
            type="button"
            onClick={clearFilter}
            className="btn btn-sm btn-block btn-secondary transition-3d-hover"
          >
            Rénitialiser
          </button>
        </div>
      </div>
    </div>
  );
};

SearchBannerEnt.propTypes = {
  setSearchValue: PropTypes.any,
  searchValue: PropTypes.any,
  filterByType: PropTypes.any,
  setFilterByType: PropTypes.any,
  date: PropTypes.any,
  focusedInput: PropTypes.any,
  setFocusedInput: PropTypes.any,
  setDate: PropTypes.any,
  isFacture: PropTypes.any,
};

export default SearchBannerEnt;
