import { discount } from 'helpers/discount';
import { getImageUrl } from 'helpers/getImageUrl';
import { priceNoZeros } from 'helpers/priceNoZeros';
import React from 'react';
import PropTypes from 'prop-types';
import { getTtcProduct } from 'helpers/getTtcProduct';
import { groupCardsByShop } from 'helpers/groupCardsByShop';
import ImageAsyncComponent from 'components/ImageAsyncComponent';

const CheckoutItems = ({ carts, user, shopUrl }) => {
  return (
    <>
      {carts &&
        groupCardsByShop(carts)?.map(card => {
          return (
            <div>
              {shopUrl.trim() === 'tousLesMagasin' && (
                <div className="d-flex justify-content-center p-3">
                  <b>{card?.shopName}</b>
                </div>
              )}

              {card?.cardsByShop?.map(cart => {
                return (
                  <div key={cart?.id} className="p-1 border-bottom col-12 mb-1 d-flex justify-content-center">
                    <div style={{ marginBottom: 10 }} className="col-md-10 col-sm-6 col-xs-12">
                      <div className="doc-table text-center row">
                        <div
                          style={{ borderRadius: 50, BorderRight: 5 }}
                          className=" my-auto d-flex justify-content-center col-2 p-1"
                        >
                          <ImageAsyncComponent height="20" image={getImageUrl(cart?.productEanId)} />
                        </div>
                        <div className="col-4 my-auto">
                          <p>{cart?.product?.title}</p>
                        </div>
                        <div className="col-2 my-auto">
                          {discount(cart?.product.articlesDiscount) !== 0 ? (
                            <>
                              <del>
                                {priceNoZeros(cart?.product?.price, 'TND')}
                                {' '}
                                (HT)
                              </del>
                              <p>
                                {priceNoZeros(
                                  cart?.product?.price -
                                    (cart?.product?.price * discount(cart.product.articlesDiscount)) / 100,
                                  'TND',
                                )}
                                {' '}
                                (HT)
                              </p>
                            </>
                          ) : (
                            <p>
                              {priceNoZeros(cart?.product?.price, 'TND')}
                              {' '}
                              (HT)
                            </p>
                          )}
                        </div>
                        <div className="col-2 my-auto p-0">
                          <p>{cart?.quantity}</p>
                        </div>
                        <div className="col-2 my-auto p-0">
                          <p style={{ overflowWrap: 'break-word' }}>
                            {getTtcProduct(cart?.product, user?.tvaList, user?.user?.user, cart?.quantity)}
                            (TTC)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </>
  );
};

CheckoutItems.propTypes = {
  carts: PropTypes.any,
  user: PropTypes.object,
  shopUrl: PropTypes.string,
};

export default CheckoutItems;
