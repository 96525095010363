import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import PropTypes from 'prop-types';
import { slugifyString } from 'helpers/slugifyString';

const CheckoutCartItem = ({ product }) => {
  const length = number => {
    return number.toString().length;
  };

  return (
    <div className="d-flex align-items-center my-2">
      <div className=" avatar avatar-lg mr-3">
        <ImageAsyncComponent image={getImageUrl(product?.product?.eanId)}> </ImageAsyncComponent>
        <sup
          style={{ width: length(product?.quantity) + 22, height: length(product?.quantity) + 22 }}
          className="avatar-status avatar-primary d-flex justify-content-center"
        >
          {product?.quantity}
        </sup>
      </div>
      <div className="media-body">
        {product?.product?.hasNoDiscount && (
          <span className="badge badge-warning badge-pill text-white">Hors promo</span>
        )}

        <div className="black d-block" to={`/products/${slugifyString(product?.product?.title)}`}>
          {product?.product?.title}
        </div>
      </div>
    </div>
  );
};

CheckoutCartItem.propTypes = {
  product: PropTypes.object.isRequired,
};
export default CheckoutCartItem;
