import { FILTER_CATEGORY0, FILTER_CATEGORY1, FILTER_CATEGORY2 } from '_redux/types/products';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from './useCreateUrl';
import useElastic from './useElastic';

const useCategories = () => {
  const dispatch = useDispatch();
  const createUrl = useCreateUrl();
  const history = useHistory();
  const { category0, category1, category2 } = useSelector(({ products }) => products);
  const { facets } = useElastic();

  const categories = facets?.category0['0']?.data;
  const subCategories = facets?.category1['0']?.data;
  const subSubCategories = facets?.category2['0']?.data;

  // eslint-disable-next-line no-unused-expressions
  categories?.forEach(cat => {
    let catItems = [];

    // eslint-disable-next-line no-unused-expressions
    subCategories?.forEach(sCat => {
      let subCatItems = [];

      if (
        JSON.parse(cat.value).label?.trim() ===
        JSON.parse(sCat.value)
          .label.split('>')[0]
          ?.trim()
      )
        catItems = [...catItems, sCat];
      // eslint-disable-next-line no-unused-expressions
      subSubCategories?.forEach(sScat => {
        if (
          JSON.parse(sCat.value)
            .label.split('>')[1]
            ?.trim() ===
          JSON.parse(sScat.value)
            .label.split('>')[1]
            ?.trim()
        )
          subCatItems = [...subCatItems, sScat];
      });

      if (subCatItems.length > 0) {
        sCat.items = subCatItems;
      }
    });
    if (catItems.length > 0) {
      cat.items = catItems;
    }
  });

  const refineCategory = (category, depth) => {
    switch (depth) {
      case 0:
        dispatch({ type: FILTER_CATEGORY0, payload: category });
        history.push(
          createUrl({
            category0: category === category0 ? 'zero' : category,
            category1: 'zero',
            category2: 'zero',
            page: '{"size":16,"current":1}',
          }),
        );

        break;
      case 1:
        dispatch({ type: FILTER_CATEGORY1, payload: category });
        history.push(createUrl({ category1: category, category2: 'zero', page: '{"size":16,"current":1}' }));

        break;
      case 2:
        dispatch({ type: FILTER_CATEGORY2, payload: category });
        history.push(createUrl({ category2: category, page: '{"size":16,"current":1}' }));
        break;
      default:
    }
  };

  return {
    refineCategory,
    categories,
    currentRefineCat0: category0,
    currentRefineCat1: category1,
    currentRefineCat2: category2,
  };
};

export default useCategories;
