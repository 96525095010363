import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import useElastic from 'hooks/useElastic';
import useBrandHeroes from 'hooks/useBrandHeroes';
import BrandFilterItem from 'components/BrandFilterItem';

const FilterBrand = () => {
  const [isCollapse, setIsCollapse] = useState(true);
  const toggleCollapse = () => setIsCollapse(!isCollapse);
  const { facets } = useElastic();
  const { currentRefinementBrand: _brand } = useBrandHeroes();

  const [searchBrandVar, setSearchBrandVar] = useState('');

  const brandsAr = (
    facets?.brand['0'].data
      .map(brand => {
        // if (brand.value === 'UCAR OYU') {
        //   const uCarBrandValue = {
        //     id: brand.value,
        //     image: brand.value,
        //     name: brand.value,
        //   };

        //   return {
        //     ...uCarBrandValue,
        //     value: JSON.stringify(uCarBrandValue),
        //     count: brand.count,
        //   };
        // }
        try {
          const { id, image, name } = JSON.parse(brand.value);

          return {
            id,
            image,
            name,
            value: brand.value,
            count: brand.count,
          };
        } catch {
          return null;
        }
      })
      .filter(e => e !== null) || []
  ).filter(e => e?.name.toLowerCase().includes(searchBrandVar.toLowerCase()));

  return (
    <div>
      <h4>Les marques</h4>

      <input
        type="text"
        className="form-control mb-2"
        onChange={event => setSearchBrandVar(event.target.value)}
        placeholder="Cherchez votre marque ..."
      />
      <div className="list-group">
        {brandsAr && (
          <>
            {brandsAr
              ?.filter((e, index) => index <= 5)
              ?.map(item => (
                <BrandFilterItem item={item} brand={_brand} />
              ))}
            <Collapse isOpen={!isCollapse}>
              {brandsAr
                .filter((e, index) => index > 5)
                .map(item => (
                  <BrandFilterItem item={item} brand={_brand} />
                ))}
            </Collapse>
          </>
        )}
      </div>

      <div onClick={toggleCollapse} className="btn btn-soft-indigo btn-block">
        {isCollapse ? 'Afficher +' : 'Afficher -'}
      </div>
    </div>
  );
};

export default FilterBrand;
