/* eslint-disable max-len */
import React from 'react';
import SEO from 'components/SEO';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgetPasswordForm from 'containers/ForgetPasswordForm';
import { reqResetPassword } from '_redux/actions/user';

const ForgetPassword = () => {
  const history = useHistory();
  const disptach = useDispatch();

  const handleSubmit = values => {
    disptach(reqResetPassword(values, history));
  };

  return (
    <>
      <SEO title="Login page" description="Se connecter à MB-Distribution" />
      <main id="content" role="main">
        <div className="d-flex justify-content-center pt-5">
          <ForgetPasswordForm onSubmit={handleSubmit} />
        </div>
      </main>
    </>
  );
};

export default ForgetPassword;
// export default Login;
