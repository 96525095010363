import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
// import ResetPasswordForm from 'containers/ResetPasswordForm';
import SafeAreaView from 'components/SafeAreaView';
import { queryToSearchObject } from 'helpers/queryToSearchObject';
import { useDispatch } from 'react-redux';
import { resetPassword } from '_redux/actions/user';
import ResetPasswordForm from 'containers/ResetPasswordForm';

const ResetPassword = () => {
  const { search } = useLocation();
  const history = useHistory();
  const query = queryToSearchObject(search);
  const dispatch = useDispatch();

  const onSubmit = ({ password }) => {
    dispatch(resetPassword({ password, resetPasswordToken: query.token }, () => history.push('/login')));
  };

  return (
    <SafeAreaView>
      <div className="container space-2 d-flex flex-column ">
        <span className="h2 font-weight-medium text-center">Modifier Votre Mot De Passe</span>
        <div>
          <ResetPasswordForm {...{ onSubmit }} />
        </div>
      </div>
    </SafeAreaView>
  );
};

export default ResetPassword;
