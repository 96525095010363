/* eslint-disable max-lines */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MB_INFO from 'constants/mbInfo';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import moment from 'moment';
import checkEntTitle from 'helpers/checkEntTitle';
import { shopNames } from 'helpers/shopNames';

const FactureHeader = ({ facture, isBlHeader, deliveredTo, modeTrasport, isDevi, isBC, cards }) => {
  const loggedUser = useSelector(({ auth }) => auth?.user?.user?.user);

  return (
    <>
      {loggedUser && (
        <>
          <div className="d-flex ">
            <div className="logo-information col-6" style={{ backgroundColor: 'white', width: 350 }}>
              <img
                alt="logo"
                src={(process.env.PUBLIC_URL, '/app/assets/images/Logo-MB-Distribution-1.png')}
                style={{ height: '80px', width: '100px' }}
              />
              <div>MB DISTRIBUTION</div>
              N° de telephone :{`${MB_INFO.phone}`}
              <div style={{ overflowWrap: 'break-word' }}>{`${MB_INFO.EMAIL}`}</div>
              {`${MB_INFO.address}`}
              <br />
              {`${MB_INFO.state}`}
              {`${MB_INFO.city}`}
            </div>
            <div className="fiscal-information rounded col-6">
              <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
                <table className="ml-2">
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          <b>{`${loggedUser?.name}` || 'NOM CLIENT'}</b>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>{loggedUser?.rue || 'Adresse Client '} </td>
                    </tr>
                    <tr>
                      <td>
                        {loggedUser?.addressF?.ZIPCOD || loggedUser?.addressF?.CPOSTAL || loggedUser?.addressF?.zipCode}
                      </td>
                    </tr>
                    <tr>
                      <td>{loggedUser?.ville || 'Ville '}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Matricule Fiscale : </strong>
                      </td>
                      <td>
                        <span> {loggedUser?.UMF} /</span>
                        <span> {loggedUser?.UTVAC} /</span>
                        <span> {loggedUser?.UCATEG} /</span>
                        <span> {loggedUser?.UETBSEC}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong> Tél :</strong>
                      </td>
                      <td>{loggedUser?.phone || 'N° tel'}</td>
                    </tr>
                    {isBlHeader && (
                      <tr>
                        <td>
                          <strong>Livré à : </strong>
                        </td>
                        <td>{deliveredTo || trimSpacesFromObject(loggedUser?.ville)}</td>
                      </tr>
                    )}
                    {isBlHeader && (
                      <tr>
                        <td>
                          <strong>Mode de transport : </strong>
                        </td>
                        <td>{modeTrasport || ''}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h1>
            <b>{checkEntTitle(facture?.picod)}</b>
          </h1>

          <div className="table2-container mt-2 rounded d-flex justify-content-center">
            <table className="table table-bordered" rules="all" style={{ border: 'solid' }}>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th>Date</th>
                  {isDevi && <th>Client</th>}
                  <th>{isDevi ? 'Votre référence' : 'Numéro pièce'}</th>
                  <th>{isDevi ? 'Magasin' : 'Code Client'}</th>
                  {facture?.address && <th>Address de livraison</th>}
                  <th>Commercial</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>{moment(facture?.creation).format('DD/MM/YYYY')}</td>
                  {isDevi && <td>{loggedUser?.name}</td>}
                  <td>{isDevi ? loggedUser?.tiers : `${facture?.refPino} ${facture?.pino}`}</td>
                  <td>{isDevi ? shopNames(cards) : loggedUser?.tiers || 'Code Client '}</td>
                  {facture?.address && <td>{facture?.address?.name}</td>}

                  <td>{isDevi ? 'Site Web' : facture?.commercial}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

FactureHeader.propTypes = {
  isBlHeader: PropTypes.bool,
  isBC: PropTypes.bool,
  isDevi: PropTypes.bool,
  modeTrasport: PropTypes.string,
  deliveredTo: PropTypes.string,
  facture: PropTypes.any,
  cards: PropTypes.any,
};
export default FactureHeader;
