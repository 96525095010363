import React from 'react';
import ProductListCard from 'components/ProductListCard';
import PropTypes from 'prop-types';

const ProductCard = ({
  idRef,
  title,
  description,
  image,
  redTag,
  price,
  quantity,
  unit,
  discount,
  reference,
  eanId,
}) => {
  return (
    <ProductListCard
      reference={reference}
      eanId={eanId}
      discount={discount}
      idRef={idRef}
      title={title}
      image={image}
      quantity={quantity}
      description={description}
      redTag={redTag}
      price={price || 0}
      unit={unit}
    />
  );
};

ProductCard.propTypes = {
  idRef: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  eanId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  description: PropTypes.string,
  redTag: PropTypes.string,
  price: PropTypes.number,
  discount: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

export default ProductCard;
