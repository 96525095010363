import React, { useEffect, useRef } from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { useRouteMatch } from 'react-router-dom';
import PICOD_TYPE from 'constants/ent';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import BCContent from 'components/BonCommande/BCContent';
import { fetchBcById } from '_redux/actions/factureBl/';
import { CircularProgress } from '@material-ui/core';
import useWindowDimensions from 'hooks/useWindowDimensions';

const BCDetailPage = () => {
  const { params } = useRouteMatch();
  const loggedUser = useSelector(({ auth }) => auth?.user?.user);
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    dispatch(fetchBcById(params.id, PICOD_TYPE.COMMANDE, trimSpacesFromObject(loggedUser?.tiers)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView>
      {loggedUser && (
        <div>
          {isLoading ? (
            <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
              <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
            </div>
          ) : (
            <>
              <div className="container overflow-auto pt-4 mt-5">
                <BCContent factureRef={componentRef} />
              </div>
              <ReactToPrint
                trigger={() => (
                  <div className="col-12 d-flex justify-content-center">
                    <button className="btn btn-soft-dark mt-6 mb-6" type="button">
                      Imprimer le Bon de commande ↓
                      <i className="fa fa-print" />
                    </button>
                  </div>
                )}
                content={() => componentRef.current}
              />
            </>
          )}
        </div>
      )}
    </SafeAreaView>
  );
};

export default BCDetailPage;
