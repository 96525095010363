import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import loadImage from 'image-promise';
import LoaderComponent from 'components/Loader';

const ImageAsyncComponent = ({ image, fallback = '/app/assets/images/LOGO-mbdistri.png', height, width }) => {
  const [src, setSrc] = useState('/app/assets/images/LOGO-mbdistri.png');
  const [loading, setLoadingImage] = useState(true);

  useEffect(() => {
    setLoadingImage(true);
    loadImage(image)
      .then(() => {
        setSrc(image);
        setLoadingImage(false);
      })
      .catch(err => {
        loadImage(fallback)
          .then(() => {
            setSrc(fallback);
            setLoadingImage(false);
          })
          .catch(erre => {
            setSrc('/app/assets/images/LOGO-mbdistri.png');
            setLoadingImage(false);
          });
      });
  }, [image, fallback]);

  return (
    <div className="position-relative" style={{ height: '100%' }}>
      {loading && (
        <div style={{ position: 'absolute', left: '40%', top: '30%', zIndex: '99' }}>
          <LoaderComponent />
        </div>
      )}

      <figure style={loading ? { filter: 'blur(7px)', height: '100%' } : { height: '100%' }} className="effect-sadie ">
        <div className="d-flex justify-content-center pt-1">
          <img
            className="product-item"
            src={src}
            alt="cart-product"
            style={{
              height,
              width,
            }}
          />
        </div>
      </figure>
    </div>
  );
};

ImageAsyncComponent.propTypes = {
  image: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fallback: PropTypes.string,
};
export default ImageAsyncComponent;
