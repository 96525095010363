/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { useSelector } from 'react-redux';
import QuantityInput from 'components/QuantityInput';
import { quantityInCart } from 'helpers/quantityInCarts';
import { priceNoZeros } from 'helpers/priceNoZeros';

const ProductListCard = ({
  idRef,
  title,
  image,
  quantity,
  description,
  redTag,
  price,
  unit,
  discount,
  reference,
  eanId,
}) => {
  const { user } = useSelector(({ auth }) => auth);
  const productCarts = user?.user?.carts?.filter(e => e.productEanId.trim() === idRef);

  const newshops = user?.shops?.filter((ele, ind) => ind === user?.shops.findIndex(elem => elem.name === ele.name));

  return (
    <ul key={idRef} style={{ padding: 10 }} className="card border shadow-none mb-3 mb-md-5">
      <li className="card border shadow-none mb-3 mb-md-4">
        <div className="col-md-12 row">
          <div className="col-md-4 my-auto">
            <span
              style={{ color: 'white', fontWeight: 'bold', marginBottom: 5 }}
              className={quantity < 10 ? 'badge badge-danger badge-pill' : undefined}
            >
              {quantity < 10 && redTag}
            </span>

            <ImageAsyncComponent image={image} height="200px" width="200px" />
          </div>
          <div className="card-body col-md-8">
            <div className="mb-2  d-flex flex-column text-left">
              <span className="d-block font-size-1">
                <div className="Container" dangerouslySetInnerHTML={{ __html: title }} />
              </span>
              <div className="d-flex flex-column text-left justify-content-start">
                {discount > 0 ? (
                  <>
                    <span className="font-weight-bold" style={{ color: '#444385' }}>
                      {priceNoZeros(price - (price * discount) / 100, unit)}
                      (HT)
                    </span>
                    <span className="text-body ml-1">
                      <del>
                        {priceNoZeros(price, unit)}
                        (HT)
                      </del>
                    </span>
                  </>
                ) : (
                  <span className="font-weight-bold" style={{ color: '#444385' }}>
                    {priceNoZeros(price, unit)}
                    (HT)
                  </span>
                )}
              </div>
            </div>
            <p
              style={{ color: '#aaaaaacf', fontSize: '0.3 rem' }}
              className="text-gray"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="card-body">
              <p>
                <span style={{ color: 'rgb(68, 67, 133)' }}>Sous reference: </span>
                {reference !== '' ? reference : "il n'y a pas de référence pour ce produit "}
              </p>
              <p>
                <span style={{ color: 'rgb(68, 67, 133)' }}>Code à barre: </span>
                {eanId}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li className="card">
        <div className="border-0 pt-0 pb-4 px-4 row">
          {newshops?.map(shop => {
            const userCard = productCarts?.filter(e => {
              return e.shop?.trim() === shop?.name?.trim();
            });

            return (
              <QuantityInput
                key={shop?.id}
                qteInCard={quantityInCart(user?.user?.carts, idRef)}
                productQuantity={quantity}
                card={userCard}
                productIdRef={idRef}
                shopAdress={shop?.name}
                adrcod={shop?.adrCode}
              />
            );
          })}
        </div>
      </li>
    </ul>
  );
};

ProductListCard.propTypes = {
  idRef: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  eanId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  quantity: PropTypes.any.isRequired,
  description: PropTypes.string,
  redTag: PropTypes.string,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

export default ProductListCard;
