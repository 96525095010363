import React from 'react';
import PropTypes from 'prop-types';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { isEntExpired } from 'helpers/isEntExpired';
import { withStyles } from '@material-ui/core/styles';
import { checkFacturePayment } from 'helpers/checkFacturePayment';

const FacturePaymentDot = ({ facture, Tooltip }) => {
  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <div>
      {isEntExpired(trimSpacesFromObject(facture?.refPino)) === '' && (
        <div className="col-1 d-flex justify-content-center pt-3">
          <>
            <HtmlTooltip
              placement="top"
              title={(
                <>
                  {checkFacturePayment(facture?.paymentList, facture.ttcmt)?.etats?.length === 0 ? (
                    <b>cette fracture n&apos;est pas encore payée</b>
                  ) : (
                    <>
                      {checkFacturePayment(facture?.paymentList, facture.ttcmt)?.etats?.map((etat, index) => {
                        const uniqKey = `abcd${index}efgh${index * 2}`;

                        return (
                          <div key={uniqKey}>
                            <b>
                              {etat?.price}
                              {' '}
                            </b>

                            {etat?.numCheque.trim() !== ''
                              ? `${etat?.paymentType}, numero : ${etat?.numCheque}`
                              : `${etat?.paymentType}`}

                            <br />
                          </div>
                        );
                      })}
                      {checkFacturePayment(facture?.paymentList, facture.ttcmt)?.percent}
                      {' '}
                      % payée
                    </>
                  )}
                </>
              )}
            >
              {checkFacturePayment(facture?.paymentList, facture.ttcmt)?.etats?.length === 0 ? (
                <i style={{ color: 'red', fontSize: '20px' }} className="fa fa-dot-circle" />
              ) : (
                <i
                  style={{
                    color:
                      checkFacturePayment(facture?.paymentList, facture.ttcmt)?.percent < 100 ? 'orange' : '#46b946',
                    fontSize: '20px',
                  }}
                  className="fa fa-dot-circle"
                />
              )}
            </HtmlTooltip>
          </>
        </div>
      )}
    </div>
  );
};

FacturePaymentDot.propTypes = {
  facture: PropTypes.object.isRequired,
  Tooltip: PropTypes.any,
};
export default FacturePaymentDot;
