import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';

const ResetPasswordForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <InputField
        name="password"
        label="Votre Mot de passe *"
        type="password"
        placeholder="********"
        validate={[Validators.required, Validators.min8]}
      />
      <InputField
        name="passwordConfirm"
        type="password"
        validate={[Validators.required, Validators.passwordConfirm]}
        label="Confirmez votre mot de passe *"
        placeholder="********"
      />
      <button className="btn btn-soft-primary align-self-end" type="submit">
        mettre à jour
      </button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'resetPasswordForm' })(ResetPasswordForm);
