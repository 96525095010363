/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardItem from 'components/CardItem';
import { useSelector } from 'react-redux';

const CartContainer = ({ userCard, user }) => {
  const showCard = shopName => () => {
    setSelectedShop(shopName);
  };
  const { shops } = useSelector(({ auth }) => auth.user);

  const newshops = shops.filter((ele, ind) => ind === shops.findIndex(elem => elem.name === ele.name));

  const [selectedShop, setSelectedShop] = useState(shops[0].name);
  const cards = userCard.filter(cart => selectedShop === cart.shop.trim());

  return (
    <div className="col-lg-8 mb-7 mb-lg-0">
      <div>
        <div className="container testimonial-group scroll">
          <div style={{ height: 80 }} className="row mb-2 mt-5">
            {newshops &&
              newshops.map(shop => {
                return (
                  <div key={shop?.id} className="item mt-4" style={{ cursor: 'pointer' }}>
                    <span
                      className={shop.name.trim() === selectedShop.trim() ? 'subItemActivated' : 'subItem'}
                      onClick={showCard(shop.name)}
                    >
                      <i className="fas fa-store-alt" />
                      {` ${shop.name}`}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        {newshops.map(shop => {
          return (
            shop.name.trim() === selectedShop && (
              <CardItem
                allCards={userCard}
                user={user}
                expanded={selectedShop}
                key={shop?.id}
                ShopKey={shop?.id}
                shopName={selectedShop}
                cards={cards}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

CartContainer.propTypes = {
  userCard: PropTypes.array,
  user: PropTypes.object,
};

export default CartContainer;
