import axios from 'axios';
import Dexie from 'dexie';

export const CLIENT_API =
  process.env.NODE_ENV === 'production' ? 'https://api.mbdistribution.tn/api/v1' : 'http://localhost:3348/api/v1';
export const SERVER_API =
  process.env.NODE_ENV === 'production' ? 'http://localhost:4369/api/v1' : 'http://localhost:3348/api/v1';

export const FPARCEL_API = 'https://www.fparcel.net';
export const ES_API = 'https://es.moncefjouets.com/api/as/v1/engines/mb-distribution-data-set/';

export const db = new Dexie('Auth');
db.version(1).stores({
  user: 'id',
});
export const clientApi = axios.create({
  baseURL: CLIENT_API,
});
export const fParcelApi = axios.create({
  baseURL: FPARCEL_API,
});

export const serverApi = axios.create({
  baseURL: SERVER_API,
  timeout: 2000000,
});

export const esApi = axios.create({
  baseURL: ES_API,
  timeout: 2000000,
  headers: {
    Authorization: `Bearer search-c2yr28d6a6zf7cka5hjiffyc`,
  },
});
export const id = 3;

export const resetAuthToken = async token => {
  if (token) {
    clientApi.defaults.headers.Authorization = `bearer ${token.trim()}`;
    serverApi.defaults.headers.Authorization = `bearer ${token.trim()}`;
  } else {
    clientApi.defaults.headers.Authorization = null;
    serverApi.defaults.headers.Authorization = null;
  }
};
