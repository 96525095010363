/* eslint-disable max-len */
import { isEmpty } from 'helpers/isEmptyObject';
import { priceNoZeros } from 'helpers/priceNoZeros';
import { shopNames } from 'helpers/shopNames';
import { totalOfCarts } from 'helpers/totalOfCarts';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { checkout } from '_redux/actions/user';
import _ from 'lodash';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CircularProgress } from '@material-ui/core';
import { getTtcPrice } from 'helpers/getTtcPrice';
import CheckoutItems from './checkoutItems';

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(({ auth }) => auth);
  const carts = user?.user?.carts;
  const userObj = user?.user?.user;
  const [btnState, setBtnState] = useState(false);

  const handleSubmit = () => {
    const grouped = _.chain(filtredCarts)
      .groupBy('shop')
      .map((value, key) => {
        return { shop: { shopName: key, adrcod: value[0].adrcod || '' }, cards: value };
      })
      .value();

    setBtnState(true);

    setTimeout(() => {
      dispatch(checkout(grouped, userObj, history));
    }, 5000);
  };

  const { shop } = useParams();
  const checkShop = shop.trim() === 'tousLesMagasin';

  const filtredCarts = checkShop ? carts && carts : carts && carts.filter(cart => cart.shop.trim() === shop.trim());

  const isLoading = useSelector(({ loading }) => loading.loading);
  const { width, height } = useWindowDimensions();

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
          <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
        </div>
      ) : (
        <>
          {!isEmpty(filtredCarts) ? (
            <div style={{ marginTop: '3%' }} className="container space-1 space-md-2 ">
              <div className="d-flex justify-content-center p-3 text-center">
                <h1>
                  {shop.trim() === 'tousLesMagasin'
                    ? `confirmer votre panier pour les magasins : ${shopNames(filtredCarts)}`
                    : `confirmer votre panier pour le magasin : ${shopNames(filtredCarts)}`}
                </h1>
              </div>
              <div id="main-tables overflow-auto">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="doc-table text-center row col-md-10 col-sm-6 col-xs-12 mb-1">
                      <div className="p-2 d-flex justify-content-center col-2 pl-1">Photo</div>
                      <div className="p-2 d-flex justify-content-center col-4 pl-1">Article</div>
                      <div className="p-2 d-flex justify-content-center col-2 pl-1">Prix</div>
                      <div className="p-2 d-flex justify-content-center col-2 pl-2">Qte</div>
                      <div className="p-2 d-flex justify-content-center col-2 pl-2">Montant</div>
                    </div>
                  </div>
                  {filtredCarts && <CheckoutItems user={user} carts={filtredCarts} shopUrl={shop} />}

                  <div className="d-flex justify-content-end mt-3">
                    <div className="row col-sm-6 d-flex justify-content-between">
                      <div className="my-auto">
                        <h3 className="my-auto">Total (HT):</h3>
                        <h3 className="my-auto">Total (TTC):</h3>
                      </div>

                      <div className="d-flex justify-content-center my-auto">
                        <h3 className="my-auto">
                          <p className="my-auto">{priceNoZeros(totalOfCarts(filtredCarts), 'TND')}</p>
                          <p className="my-auto">{getTtcPrice(filtredCarts, user?.tvaList, userObj)}</p>
                        </h3>
                      </div>
                      <div className="col-md-4 my-auto d-flex justify-content-center">
                        {!btnState ? (
                          <button
                            onClick={handleSubmit}
                            disabled={btnState}
                            type="button"
                            className="my-auto btn btn-primary btn-pill transition-3d-hover text-white mb-1"
                          >
                            commender
                            <i className="fa fa-check" />
                          </button>
                        ) : (
                          <div className="lds-dual-ring" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '2%' }} className="container space-1 space-md-2 ">
              <div className="d-flex justify-content-center p-3">
                <h1>il ñ y a pas des commandes pour valider</h1>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutPage;
