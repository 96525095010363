/* eslint-disable max-lines */
import { fetchProducts } from '_redux/actions/products';
import { createFilters } from 'helpers/createFilters';
import { queryToSearchObject } from 'helpers/queryToSearchObject';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ADAPT_QUERY_TO_FILTER, intialSearchState } from '_redux/types/products';
import { errorHandler } from 'helpers/errorHandler';

export const refineWithQuery = (key, currentKeyRefine, payload = {}, searchObjectFilter) => {
  if (key === 'query' || key === 'page' || key === 'sort')
    return {
      ...payload,
      [key]: searchObjectFilter[key],
    };
  if (
    key === 'hero' ||
    key === 'brand' ||
    key === 'ages' ||
    key === 'price' ||
    key === 'category0' ||
    key === 'category1' ||
    key === 'category2'
  ) {
    if (currentKeyRefine) {
      if (
        JSON.stringify(decodeURI(searchObjectFilter[key])) !== JSON.stringify(currentKeyRefine) &&
        decodeURI(searchObjectFilter[key])
      )
        return {
          ...payload,
          [key]:
            key === 'ages' || key === 'price'
              ? JSON.parse(decodeURI(searchObjectFilter[key]))
              : decodeURI(searchObjectFilter[key]),
        };
    } else if (decodeURI(searchObjectFilter[key])) {
      return {
        ...payload,
        [key]:
          key === 'ages' || key === 'price'
            ? JSON.parse(decodeURI(searchObjectFilter[key]))
            : decodeURI(searchObjectFilter[key]),
      };
    }
  }

  return undefined;
};
export const useElasticHits = async () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchQuery, articles } = useSelector(({ products }) => products);

  useEffect(() => {
    try {
      if (location.pathname === '/products' && decodeURI(location.search) === '' && !articles) {
        dispatch(fetchProducts(intialSearchState));
      }

      if (decodeURI(location.search) !== searchQuery && location.pathname === '/products') {
        const searchObjectFilter = queryToSearchObject(decodeURI(location.search));

        let payload;

        Object.keys(searchObjectFilter).forEach(key => {
          if (key === 'category0') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'category1') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'category2') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'brand') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'hero') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'ages') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'price') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'page') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'query') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
          if (key === 'sort') payload = refineWithQuery(key, undefined, payload, searchObjectFilter);
        });
        payload &&
          dispatch({
            type: ADAPT_QUERY_TO_FILTER,
            payload: { ...payload, searchQuery: location.search },
          });
        dispatch(
          fetchProducts({
            ...intialSearchState,
            ...createFilters({ ...payload }),
          }),
        );
      }
    } catch (error) {
      errorHandler(error);
      history.push('/wrongUrl');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
};
