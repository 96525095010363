import React from 'react';
import { toTitleCase } from 'helpers/toTitleCase';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from 'hooks/useCreateUrl';

const HierarchicalMenu = ({
  items,
  refineCategory,
  currentRefineCat0,
  currentRefineCat1,
  currentRefineCat2,
  depth = 0,
}) => {
  const history = useHistory();
  const createUrl = useCreateUrl();
  const checkRefinement = (value, dep) => {
    if (dep === 0) return value === currentRefineCat0;
    if (dep === 1) return value === currentRefineCat1;
    if (dep === 2) return value === currentRefineCat2;

    return false;
  };
  const checkSelected = (value, dep) => {
    if (dep === 0 && !currentRefineCat1) return value === currentRefineCat0;
    if (dep === 1 && !currentRefineCat2) return value === currentRefineCat1;
    if (dep === 2) return value === currentRefineCat2;

    return false;
  };

  return (
    <>
      {items?.map((item, i) => {
        return (
          <div key={`${item.value}+${i + 1}`}>
            <div
              className="pb-2"
              onClick={() => {
                if (checkSelected(item.value, depth))
                  history.push(createUrl({ [`category${depth}`]: 'zero', page: '{"size":16,"current":1}' }));
                else history.push(createUrl({ [`category${depth}`]: item.value, page: '{"size":16,"current":1}' }));
                refineCategory(item.value, depth);
                item.isSelected = true;
              }}
            >
              <button
                type="button"
                className={`${
                  checkSelected(item.value, depth) ? 'active' : ''
                } list-group-item list-group-item-action ${depth ? `ml-${2 * depth + 3}` : ''}`}
              >
                <i className="fa-user-circle fa min-w-4rem mr-1" />
                {toTitleCase(JSON.parse(item.value).label)}
                <span
                  className={`badge badge-${
                    checkSelected(item.value, depth) ? 'light' : 'primary'
                  } badge-pill float-right ml-1`}
                >
                  {item.count}
                </span>
              </button>
            </div>
            {checkRefinement(item.value, depth) && (
              <HierarchicalMenu
                // createURL={createURL}
                items={item.items}
                depth={depth + 1}
                currentRefineCat0={currentRefineCat0}
                currentRefineCat1={currentRefineCat1}
                currentRefineCat2={currentRefineCat2}
                refineCategory={refineCategory}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

HierarchicalMenu.propTypes = {
  items: PropTypes.array,
  refineCategory: PropTypes.func,
  currentRefineCat0: PropTypes.string,
  currentRefineCat1: PropTypes.string,
  currentRefineCat2: PropTypes.string,
  depth: PropTypes.any,
};

export default HierarchicalMenu;
