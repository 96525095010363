import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateElastic } from '_redux/actions/products';
import PropTypes from 'prop-types';

export const UpdateProductElastic = ({ openModalUpdateProducts, setOpenModalUpdateProducts }) => {
  const history = useHistory();
  const disptach = useDispatch();
  const _handleSubmit = () => {
    disptach(updateElastic(history));
    setOpenModalUpdateProducts(!openModalUpdateProducts);
  };

  return (
    <div>
      <div className="modal-body">
        <p>Etes-vous sûre de vouloir mettre à jour la list des produits</p>
      </div>
      <div className="modal-footer">
        <button onClick={_handleSubmit} type="button" className="btn btn-primary">
          Confirmer
        </button>
        <button
          type="button"
          onClick={() => setOpenModalUpdateProducts(false)}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

export default UpdateProductElastic;

UpdateProductElastic.propTypes = {
  openModalUpdateProducts: PropTypes.func.isRequired,
  setOpenModalUpdateProducts: PropTypes.func.isRequired,
};
