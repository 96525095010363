import React from 'react';
import PropTypes from 'prop-types';
import { useCreateUrl } from 'hooks/useCreateUrl';
import { Link } from 'react-router-dom';

const BrandFilterItem = ({ item, brand }) => {
  const createUrl = useCreateUrl();

  return (
    <div className="mb-2">
      <Link
        to={createUrl({
          brand: item.value === brand ? 'zero' : item.value,
          page: '{"size":16,"current":1}',
        })}
      >
        <button type="button" className="list-group-item list-group-item-action">
          <div className="m-0 d-flex align-content-center justify-content-between">
            <div className="d-flex align-content-center">
              <div
                style={{
                  backgroundImage: `url(https://api.moncefjouets.com/api/v1/${item.image})`,
                  cursor: 'pointer',
                  width: '2em',
                  height: '2em',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  position: 'relative',
                  backgroundSize: 'contain',
                }}
              />
              <span className="my-auto ml-2">{item?.name}</span>
            </div>

            <span style={{ height: 15 }} className="badge badge-primary badge-pill my-auto d-flex align-content-center">
              <span className="my-auto">{item?.count}</span>
            </span>
          </div>
        </button>
      </Link>
    </div>
  );
};

BrandFilterItem.propTypes = {
  item: PropTypes.object,
  brand: PropTypes.string,
};

export default BrandFilterItem;
