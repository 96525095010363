export const shopNames = carts => {
  const names = [];

  if (!carts) return 'aucune magasin trouvee';
  carts.forEach(shop => {
    names.push(`${shop.shop.trim()} `);
  });

  const uniqueNames = Array.from(new Set(names));

  if (uniqueNames.length === 1) uniqueNames[0] = uniqueNames[0].replace(',', '');
  else uniqueNames[uniqueNames.length - 1] = uniqueNames[uniqueNames.length - 1].replace(',', '');

  return uniqueNames;
};
