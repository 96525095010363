/* eslint-disable no-nested-ternary */
import React from 'react';
import CustomizedBreadcrumbs from 'components/CustomizedBreadcrumbs';
import usePrice from 'hooks/usePrice';
import useAge from 'hooks/useAge';
import useBrandHeroes from 'hooks/useBrandHeroes';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from 'hooks/useCreateUrl';

const Chips = () => {
  const { price, refinePrice } = usePrice();
  const { currentRefinmentAges, refineAge } = useAge();
  const createUrl = useCreateUrl();
  const history = useHistory();
  const { currentRefinementBrand, currentRefinementHero, refineBrand, refineHero } = useBrandHeroes();

  return (
    <div className="d-flex flex-wrap">
      {price && <CustomizedBreadcrumbs textInfo={`Prix (${price.from}-${price.to})`} onClick={() => refinePrice()} />}
      {currentRefinmentAges &&
        currentRefinmentAges.map(age => (
          <CustomizedBreadcrumbs
            textInfo={`Age (${age.ages})`}
            onClick={() => {
              history.push(createUrl({ ages: 'zero' }));

              refineAge(currentRefinmentAges?.filter(o => o?.ages !== age.ages));
            }}
          />
        ))}
      {currentRefinementBrand && (
        <CustomizedBreadcrumbs
          textInfo={`Marque : ${JSON.parse(currentRefinementBrand).name}`}
          onClick={() => {
            history.push(createUrl({ brand: 'zero' }));
            refineBrand();
          }}
        />
      )}
      {currentRefinementHero && (
        <CustomizedBreadcrumbs
          textInfo={`Héro : ${JSON.parse(currentRefinementHero).name}`}
          onClick={() => {
            history.push(createUrl({ hero: 'zero' }));

            refineHero();
          }}
        />
      )}
    </div>
  );
};

export default Chips;
