import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SafeAreaView from 'components/SafeAreaView';
import { useElasticHits } from 'hooks/useElasticHits';
import Collapsible from 'components/Collapsible';
import useMobileDetect from 'use-mobile-detect-hook';
import ProductsContainer from 'containers/ProductsContainer';
import FiltersContainer from 'containers/FilterContainer';
import { useSelector } from 'react-redux';

const ProductsListPage = () => {
  const { search } = useLocation();
  const isMobile = useMobileDetect().isMobile();
  const { shops } = useSelector(({ auth }) => auth.user);

  useElasticHits();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  return (
    <SafeAreaView>
      <div className="container space-top-1 space-bottom-2 space-bottom-lg-3">
        <h1 className="pb-5 pt-5">Liste des Produits</h1>
        <div className="row">
          <div className="col-lg-3">
            {isMobile ? (
              <Collapsible title="Filtres">
                <FiltersContainer shops={shops} />
              </Collapsible>
            ) : (
              <FiltersContainer shops={shops} />
            )}
          </div>
          <div className="col-lg-9 pl-lg-7">
            <ProductsContainer />
          </div>
        </div>
      </div>
    </SafeAreaView>
  );
};

export default React.memo(ProductsListPage);
