import React from 'react';
import writtenNumber from 'written-number';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DevisContent from 'components/Devis/DevisContent';
import FactureHeader from '../FactureHeader';
import FactureFooter from '../FactureFooter';

const FactureContent = ({ factureRef, cards, isDevi }) => {
  writtenNumber.defaults.lang = 'fr';
  const loggedUser = useSelector(({ auth }) => auth?.user?.user);
  const { facture, tvaList } = useSelector(({ factureBlReducer }) => factureBlReducer);
  const maxRows = 30;

  const tableRow = mouvs => {
    return (
      <tr key={mouvs?.id}>
        <td style={{ textAlign: 'left' }}>{mouvs?.reference}</td>
        <td style={{ textAlign: 'left' }}>{mouvs?.refProduit?.REFFO}</td>
        <td style={{ textAlign: 'left' }}>{mouvs?.title}</td>
        <td style={{ textAlign: 'right' }}>{mouvs?.quantity}</td>
        <td style={{ textAlign: 'right' }}>{mouvs?.pub}</td>
        <td style={{ textAlign: 'right' }}>{mouvs?.discount}</td>
        <td style={{ textAlign: 'right' }}>{mouvs?.price}</td>
      </tr>
    );
  };
  const tabHeader = () => {
    return (
      <tr>
        <th width="130">Code à barre</th>
        <th width="140">RFO</th>
        <th>Désignation</th>
        <th width="40">Qte</th>
        <th width="80">
          PU
          <small>(HT)</small>
        </th>
        <th width="40">R%</th>
        {isDevi ? (
          <th width="80">Montant</th>
        ) : (
          <th style={{ textAlign: 'center' }} width="80">
            Montant 
            {' '}
            <small>(TTC)</small>
          </th>
        )}
      </tr>
    );
  };

  let subTotal = 0;

  return (
    <>
      {isDevi ? (
        <DevisContent tabHeader={tabHeader} cards={cards} factureRef={factureRef} isDevi={isDevi} />
      ) : 
        loggedUser &&
        facture &&
        tvaList && (
          <div size="A4" className="factureContainer py-9 factureInfo ">
            <div className="ml-5 mr-5 mt-3" ref={factureRef}>
              <FactureHeader
                isDevi={isDevi}
                facture={facture}
                deliveredTo={facture?.deliveredTo}
                modeTrasport={facture?.modeTrasport}
              />
              <div>
                <table cellSpacing={3} cellPadding={5} className="tableFix" rules="all">
                  {tabHeader()}
                  {facture?.mvtls?.map((mouv, index) => {
                    subTotal += parseFloat(mouv?.mouvDivalto?.price);
                    if ((index + 1) % maxRows === 0) {
                      const subfinal = subTotal;

                      subTotal = 0;

                      return (
                        <>
                          {tableRow(mouv?.mouvDivalto)}
                          <tr className="subtotal" style={{ textAlign: 'center' }}>
                            <th colSpan={4}>Sous-total</th>
                            <th colSpan={3}>{subfinal.toFixed(3)}</th>
                          </tr>
                          {facture?.mvtls?.length > maxRows && <div className="page-counter" />}
                          <tr className="x" />
                          {tabHeader()}
                        </>
                      );
                    }

                    return <>{tableRow(mouv?.mouvDivalto)}</>;
                  })}
                </table>
              </div>
              <FactureFooter cards={cards} facture={facture} tvaList={tvaList} />
              {facture?.mvtls?.length > maxRows && <div className="page-counter" />}
            </div>
          </div>
        )}
    </>
  );
};

FactureContent.propTypes = {
  factureRef: PropTypes.any,
  cards: PropTypes.any,
  isDevi: PropTypes.bool,
};
export default FactureContent;
