/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import useElastic from 'hooks/useElastic';
import CustomSortBy from 'components/CustomSortBy';

const ProductsListHeader = ({ productsCount }) => {
  const { meta } = useElastic();

  return (
    <div className="row align-items-center mb-5 justify-content-between">
      <div className="col-lg-3 mb-3 mb-lg-0">
        <span className="font-size-1 ml-1">
          {`${meta?.page?.total_results} produit${meta?.page?.total_results < 2 ? '' : 's'} trouvé${
            meta?.page?.total_results < 2 ? '' : 's'
          }`}
        </span>
      </div>
      <div className="col-lg-3 mb-5 mb-lg-0" />
      <div className="col-lg-5  text-lg-right d-none d-md-block">
        <ul className="list-inline mb-0">
          <li className="list-inline-item w-50">
            <CustomSortBy
              items={[
                { value: 'asc', label: '↑ Prix croissant' },
                { value: 'dsc', label: '↓ Prix décroissant' },
              ]}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

ProductsListHeader.propTypes = {
  productsCount: PropTypes.number,
};
export default ProductsListHeader;
