import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { totalOfCarts } from 'helpers/totalOfCarts';
import CheckoutCartItem from 'components/CheckoutCartItem';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { ModalBody } from 'reactstrap';
import Devis from 'components/Devis';
import ExcelToJson from 'components/ExcelToJson';
import { priceNoZeros } from 'helpers/priceNoZeros';
import { getTtcPrice } from 'helpers/getTtcPrice';
import { deleteAllCart } from '_redux/actions/user';

const CheckoutContainer = ({ userCarts, user }) => {
  const { shops } = useSelector(({ auth }) => auth.user);
  const newshops = shops.filter((ele, ind) => ind === shops.findIndex(elem => elem.name === ele.name));
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="col-lg-4">
      <div className="pl-lg-4">
        <div className="card shadow-soft p-4 mb-4">
          <div className="pb-2 mb-4">
            <ExcelToJson />
            <button
              className="btn btn-danger btn-pill transition-3d-hover text-white ml-1 mb-1"
              type="button"
              onClick={() => dispatch(deleteAllCart())}
            >
              Supprimer tous les produits
            </button>
            <h2 className="h3 mb-0">Tout le panier</h2>
          </div>

          {newshops &&
            newshops.map((shop, index) => {
              const tmp = index + 1;

              return (
                <div key={tmp}>
                  <hr />
                  <div>
                    <i className="fas fa-store-alt" />
                    {` ${shop.name.trim()}`}
                  </div>
                  {userCarts.map((userCart, key) => {
                    const tmp2 = key + 1;

                    return (
                      shop.name.trim() === userCart.shop.trim() && <CheckoutCartItem key={tmp2} product={userCart} />
                    );
                  })}
                </div>
              );
            })}

          <div className="media d-flex justify-content-between mt-3 mb-3">
            <div className="d-flex flex-column">
              <span className="d-block font-size-1 mr-3">Total (HT)</span>
            </div>
            <div className="d-flex flex-column">
              <span className="text-body ml-1">{priceNoZeros(totalOfCarts(userCarts), 'TND')}</span>
            </div>
          </div>
          <div className="media d-flex justify-content-between mb-3">
            <div className="d-flex flex-column">
              <span className="d-block font-size-1 mr-3">Total à payer (TTC)</span>
            </div>
            <div className="d-flex flex-column">
              <span className="text-body ml-1">{getTtcPrice(userCarts, user?.tvaList, user?.user?.user)}</span>
            </div>
          </div>
        </div>

        <div className="row mx-1">
          <Modal
            className="myModal"
            isOpen={openModal}
            closeModal={() => setOpenModal(false)}
            title={<span>Demande un devis</span>}
          >
            <ModalBody>
              <Devis cards={userCarts} />
            </ModalBody>
          </Modal>
          <div className="col px-1 my-1">
            <Link
              to="/checkout/tousLesMagasin"
              className="btn btn-primary btn-block btn-pill transition-3d-hover text-white"
            >
              Commander tous
            </Link>
            <div className="col px-1 my-1 d-flex justify-content-center">
              <button
                className="btn btn-success btn-pill transition-3d-hover text-white ml-1 mb-1"
                type="button"
                onClick={() => setOpenModal(true)}
              >
                Demande un Devis pour tous
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckoutContainer.propTypes = {
  userCarts: PropTypes.array,
  user: PropTypes.object,
};
export default CheckoutContainer;
