import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { HeaderModalsContainer } from './HeaderModalsContainer';

const HeaderLinks = ({ user, dropdownClass, handleDropdown, dropdown, dropdownMenu, width }) => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalAddPromo, setOpenModalAddPromo] = useState(false);
  const [openModalUpdateProducts, setOpenModalUpdateProducts] = useState(false);

  const mails = process.env.REACT_APP_ADMIN_EMAILS;

  return (
    <>
      <ul className="pl-5 navbar-nav mr-auto my-2 my-lg-0 pull-right">
        <li className="nav-item">
          <NavLink
            to='/products?sort=asc&page={"size":16,"current":1}'
            className="nav-link"
            activeClassName={width > 991 ? 'activeNav' : 'activeNavMobile'}
          >
            Nos Produits
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/factures" className="nav-link" activeClassName={width > 991 ? 'activeNav' : 'activeNavMobile'}>
            Factures
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/bon-de-livraison"
            className="nav-link"
            activeClassName={width > 991 ? 'activeNav' : 'activeNavMobile'}
          >
            Bon de livraison
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/Bon-de-commande"
            className="nav-link"
            activeClassName={width > 991 ? 'activeNav' : 'activeNavMobile'}
          >
            Bon de commande
          </NavLink>
        </li>

        {mails && mails.includes(user?.email) && (
          <li className={`nav-item ${dropdownClass}`}>
            <button
              style={{ border: 0, backgroundColor: 'transparent' }}
              type="button"
              onClick={() => handleDropdown()}
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={dropdown}
            >
              Admin
            </button>
            <div className={dropdownMenu} aria-labelledby="navbarDropdown">
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  setOpenModalAddPromo(true);
                  handleDropdown();
                }}
              >
                Promotion
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  setOpenModalAdd(true);
                  handleDropdown();
                }}
              >
                Ajouter Client
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  setOpenModalUpdateProducts(true);
                  handleDropdown();
                }}
              >
                Mises à jour des produits
              </button>
            </div>
          </li>
        )}
      </ul>
      <HeaderModalsContainer
        openModalAdd={openModalAdd}
        openModalAddPromo={openModalAddPromo}
        setOpenModalAdd={setOpenModalAdd}
        setOpenModalAddPromo={setOpenModalAddPromo}
        setOpenModalUpdateProducts={setOpenModalUpdateProducts}
        openModalUpdateProducts={openModalUpdateProducts}
      />
    </>
  );
};

HeaderLinks.propTypes = {
  user: PropTypes.object,
  dropdown: PropTypes.bool,
  width: PropTypes.number,
  dropdownMenu: PropTypes.string,
  dropdownClass: PropTypes.string,
  handleDropdown: PropTypes.func,
};
export default HeaderLinks;
