import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'containers/SearchBar';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HeaderTopBar from './HeaderTopBar';
import HeaderLinks from './HeaderLinks';

export const Header = ({ isLogged }) => {
  const [collapse, setCollapse] = useState(false);
  const [collapseClass, setCollapseClass] = useState('collapse');
  const [dropdown, setDropdown] = useState(false);
  const [dropdownClass, setDropdownClass] = useState('dropdown');
  const [dropdownMenu, setDropdownMenu] = useState('dropdown-menu');
  const { width, height } = useWindowDimensions();

  const handleCollapse = () => {
    setCollapse(!collapse);
    collapseClass === 'collapse' ? setCollapseClass('collaping') : setCollapseClass('collapse');
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
    dropdownClass === 'dropdown' ? setDropdownClass('dropdown show') : setDropdownClass('dropdown');
    dropdownMenu === 'dropdown-menu' ? setDropdownMenu('dropdown-menu show') : setDropdownMenu('dropdown-menu');
  };

  const handleCloseDropdown = () => {
    dropdown && handleDropdown();
  };

  const user = useSelector(({ auth }) => auth);

  return (
    <div onClick={() => handleCloseDropdown()}>
      <nav
        style={{ marginBottom: '15% !important' }}
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
      >
        <Link className="navbar-brand pl-4" to='/products?sort=asc&page={"size":16,"current":1}' aria-label="Front">
          <img
            src="/app/assets/images/Logo-MB-Distribution-1.png"
            className="logo-moncef"
            alt="Logo"
            style={{ width: '70%' }}
          />
        </Link>
        {width < 991 && (
          <div>
            <div className="row">
              <HeaderTopBar user={user} isLoggedIn={isLogged} />
            </div>
            {isLogged && (
              <div className="row d-flex justify-content-center">
                <button className="navbar-toggler" onClick={() => handleCollapse()} type="button">
                  {!collapse ? <span className="navbar-toggler-icon" /> : <span style={{ fontSize: '30px' }}>X</span>}
                </button>
              </div>
            )}
          </div>
        )}

        {isLogged && (
          <div className={`navbar-collapse ${collapseClass}`} id="navbarSupportedContent">
            {width > 991 ? (
              <div className="col-5 pr-3">
                <SearchBar />
              </div>
            ) : (
              <SearchBar />
            )}
            <HeaderLinks
              width={width}
              user={user?.user?.user?.user}
              dropdown={dropdown}
              dropdownClass={dropdownClass}
              dropdownMenu={dropdownMenu}
              handleDropdown={handleDropdown}
            />
          </div>
        )}
        {width > 991 && (
          <div>
            <HeaderTopBar user={user} isLoggedIn={isLogged} />
          </div>
        )}
      </nav>
      <div style={{ backgroundColor: 'transparent', position: 'fixed', width, height }} />
    </div>
  );
};

Header.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};
