import { FETCH_ENTS_BY_USER, FETCH_FACTURES_BY_USER, FETCH_TVA_LIST } from '_redux/types/ents';
import { clientApi } from 'api';
import { toast } from 'react-toastify';
import { errorHandler } from 'helpers/errorHandler';
import { startLoading, stopLoading } from '../Loading';

const api = '/ent-divalto/';

export const fetchEntsUser = (tiers, picod) => async dispatch => {
  dispatch(startLoading());

  try {
    const response = await clientApi.get(`${api}${tiers}/${picod}`);

    dispatch({ type: FETCH_ENTS_BY_USER, payload: response.data.entDivalto });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
export const fetchFacturesUser = (id, picod, tiers) => async dispatch => {
  dispatch(startLoading());

  try {
    const response = await clientApi.get(`${api}byId/${id}/${picod}/${tiers}`);

    dispatch({ type: FETCH_TVA_LIST, payload: response.data.tvaList });
    dispatch({ type: FETCH_FACTURES_BY_USER, payload: response.data.entDivalto });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
