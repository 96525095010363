export const TOGGLE_IS_GRID = 'TOGGLE_IS_GRID';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_CSV = 'FETCH_CSV';
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_BEST_SELLERS = 'FETCH_BEST_SELLERS';
export const FETCH_PRODUCT_BY_REF = 'FETCH_PRODUCT_BY_REF';
export const SEARCH_STATE_CHANGE = 'SEARCH_STATE_CHANGE';
export const ELASTIC_STATE_CHANGE = 'ELASTIC_STATE_CHANGE';
export const FILTER_HERO = 'FILTER_HERO';
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_PRICE = 'FILTER_PRICE';
export const FILTER_AGE = 'FILTER_AGE';
export const FILTER_CATEGORY0 = 'FILTER_CATEGORY0';
export const FILTER_CATEGORY1 = 'FILTER_CATEGORY1';
export const FILTER_CATEGORY2 = 'FILTER_CATEGORY2';
export const RESET_FILTERS = 'RESET_FILTERS';
export const FETCH_EXCEL = 'FETCH_EXCEL';
export const FILTER_ASC = 'FILTER_ASC';
export const FILTER_DSC = 'FILTER_DSC';
export const FILTER_QUERY = 'FILTER_QUERY';
export const FILTER_PAGE = 'FILTER_PAGE';
export const ADAPT_QUERY_TO_FILTER = 'ADAPT_QUERY_TO_FILTER';
export const UPDATE_ELASTIC_MANUEL = 'UPDATE_ELASTIC_MANUEL';

export const intialSearchState = {
  sort: [{ _score: 'desc' }, { price: 'asc' }],
  query: '',
  facets: {
    brand: {
      type: 'value',
      size: 100,
    },
    category0: {
      type: 'value',
      size: 100,
    },
    category1: {
      type: 'value',
      size: 100,
    },
    category2: {
      type: 'value',
      size: 100,
    },
  },
  filters: {
    all: [],
  },
  result_fields: {
    quantity: {
      raw: {},
    },
    saref: {
      raw: {},
    },

    has_no_discount: {
      raw: {},
    },

    discountart: {
      raw: {},
    },
    tvaart: {
      raw: {},
    },
    uref1: { raw: {} },
    uref2: { raw: {} },
    eanid: { raw: {} },
    reference: { raw: {} },

    description: {
      snippet: {
        size: 100,
        fallback: true,
      },
    },

    title: {
      raw: {
        size: 250,
      },
      snippet: {
        size: 100,
        fallback: true,
      },
    },

    price: {
      raw: {},
    },

    id: {
      raw: {},
    },
  },
  search_fields: {
    description: { weight: 3.5 },
    title: { weight: 7 },
    reference: { weight: 1 },
    brand: { weight: 1 },
    id: { weight: 1 },
  },
  page: {
    size: 16,
    current: 1,
  },
};
