import React from 'react';
import ExcelToJson from 'components/ExcelToJson';
import PropTypes from 'prop-types';

const PromotionPage = ({ openModalAddPromo, setOpenModalAddPromo }) => {
  return <ExcelToJson promo openModalAddPromo={openModalAddPromo} setOpenModalAddPromo={setOpenModalAddPromo} />;
};

PromotionPage.propTypes = {
  openModalAddPromo: PropTypes.bool.isRequired,
  setOpenModalAddPromo: PropTypes.func.isRequired,
};

export default PromotionPage;
