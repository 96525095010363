import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from 'containers/Header';
import CardPage from 'pages/CardPage';
import React, { useEffect } from 'react';
import LoginPage from 'pages/Login';
import { checkLoggingStatus } from '_redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import ProductsListPage from 'pages/ProductsListPage';
import FactureDetailPage from 'pages/FactureDetailPage';
import FacturePage from 'pages/FacturePage';
import BLPage from 'pages/BLPage';
import BLDetailPage from 'pages/BLDetailPage';
import CheckoutPage from 'pages/CheckoutPage';
import BCPage from 'pages/BCPage';
import BCDetailPage from 'pages/BCDetailPage';
import ForgetPassword from 'pages/ForgetPassword';
import ResetPassword from 'pages/ResetPassword';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CircularProgress } from '@material-ui/core';
import NotFound from 'pages/NotFound';
import PrivateRoute from './PrivateRoute';
import LoggedOutRoute from './LoggedOutRoute';

const App = () => {
  const dispatch = useDispatch();
  const { isLogged, loaded } = useSelector(({ auth }) => auth);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    dispatch(checkLoggingStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded)
    return (
      <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
        <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
      </div>
    );

  return (
    <div>
      <div>
        <Header isLogged={isLogged} />
      </div>

      <div className="pt-5 mt-5">
        <Switch>
          <Route exact path="/">
            <Redirect to='/products?sort=asc&page={"size":16,"current":1}' />
          </Route>
          <LoggedOutRoute exact path="/login" component={LoginPage} />
          <LoggedOutRoute exact path="/mot-de-passe-oublié" component={ForgetPassword} />
          <LoggedOutRoute exact path="/resetpassword" component={ResetPassword} />
          <PrivateRoute exact path="/factures" component={FacturePage} />
          <PrivateRoute exact path="/facture/:id" component={FactureDetailPage} />
          <PrivateRoute exact path="/bon-de-livraison" component={BLPage} />
          <PrivateRoute exact path="/bon-de-livraison/:id" component={BLDetailPage} />
          <PrivateRoute exact path="/bon-de-commande" component={BCPage} />
          <PrivateRoute exact path="/bon-de-commande/:id" component={BCDetailPage} />
          <PrivateRoute exact path="/products" component={ProductsListPage} />
          <PrivateRoute exact path="/card" component={CardPage} />
          <PrivateRoute exact path="/checkout/:shop" name="checkout" component={CheckoutPage} />
          <PrivateRoute component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default App;
