import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import CreateUserPage from 'pages/CreateUserPage';
import PromotionPage from 'pages/PromotionPage';
import UpdateProductElastic from 'pages/UpdateProductElastic';

export const HeaderModalsContainer = ({
  openModalAdd,
  openModalAddPromo,
  openModalUpdateProducts,
  setOpenModalAdd,
  setOpenModalAddPromo,
  setOpenModalUpdateProducts,
}) => {
  return (
    <>
      <Modal
        className="myModal"
        isOpen={openModalAdd}
        closeModal={() => setOpenModalAdd(false)}
        title={<span>Ajouter un client</span>}
      >
        <CreateUserPage setOpenModalAdd={setOpenModalAdd} openModalAdd={openModalAdd} />
      </Modal>
      <Modal
        className="myModal"
        isOpen={openModalAddPromo}
        closeModal={() => setOpenModalAddPromo(false)}
        title={<span>Ajouter des promotions via excel</span>}
      >
        <PromotionPage setOpenModalAddPromo={setOpenModalAddPromo} openModalAddPromo={openModalAddPromo} />
      </Modal>
      <Modal
        className="myModal"
        isOpen={openModalUpdateProducts}
        closeModal={() => setOpenModalUpdateProducts(false)}
        title={<span>Mettre à jour la list des produits</span>}
      >
        <UpdateProductElastic
          setOpenModalUpdateProducts={setOpenModalUpdateProducts}
          openModalUpdateProducts={openModalUpdateProducts}
        />
      </Modal>
    </>
  );
};

HeaderModalsContainer.propTypes = {
  openModalAdd: PropTypes.bool.isRequired,
  openModalAddPromo: PropTypes.bool.isRequired,
  setOpenModalAdd: PropTypes.func.isRequired,
  setOpenModalAddPromo: PropTypes.func.isRequired,
  setOpenModalUpdateProducts: PropTypes.func.isRequired,
  openModalUpdateProducts: PropTypes.func.isRequired,
};
