import React from 'react';
import SafeAreaView from 'components/SafeAreaView';
import CartContainer from 'containers/CartContainer';
import CheckoutContainer from 'containers/CheckoutContainer';
import { useSelector } from 'react-redux';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import ExcelToJson from 'components/ExcelToJson';

const CardPage = () => {
  const { user } = useSelector(({ auth }) => auth);
  const carts = user?.user?.carts;

  return (
    <SafeAreaView>
      <>
        {numberItemInCarts(carts) !== 0 ? (
          <div className="container  space-1 space-md-2">
            <div className="row">
              {carts && <CartContainer user={user} userCard={carts} />}
              {carts && <CheckoutContainer user={user} userCarts={carts} />}
            </div>
          </div>
        ) : (
          <>
            <div className="container  space-1 space-md-2">
              <div className="row">
                <div className="container-xl space-1 space-md-2 mt-1">
                  <h5>VOTRE PANIER EST VIDE, IMPORTER VOTRE PANIER VIA UN FICHIER EXCEL :</h5>
                  <ExcelToJson />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </SafeAreaView>
  );
};

export default CardPage;
