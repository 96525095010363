import React, { useEffect } from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListBCUser } from '_redux/actions/factureBl/';
import PICOD_TYPE from 'constants/ent';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import ListBC from 'containers/ListBC';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CircularProgress } from '@material-ui/core';

const BCPage = () => {
  const loggedUser = useSelector(({ auth }) => auth?.user?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListBCUser(trimSpacesFromObject(loggedUser?.tiers), PICOD_TYPE.COMMANDE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { bonCommandeList } = useSelector(({ factureBlReducer }) => factureBlReducer);
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { width, height } = useWindowDimensions();

  return (
    <SafeAreaView>
      {isLoading ? (
        <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
          <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
        </div>
      ) : (
        <div className="container space-top-1 space-bottom-2 space-bottom-lg-3">
          <div className="row">
            {bonCommandeList && loggedUser && (
              <>
                <div className="container space-1 space-md-2">
                  <div className="row">
                    <ListBC BL={bonCommandeList} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </SafeAreaView>
  );
};

export default BCPage;
