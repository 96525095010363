import moment from 'moment';

export const discount = promotions => {
  let Promos = [];

  let dis = 0;

  if (promotions.length) {
    Promos = promotions.find(promo => {
      const today = moment();

      if (moment(promo.startDate).isBefore(today) && moment(promo.endDate).isAfter(today)) {
        return true;
      }

      return false;
    });

    dis = Promos ? Promos.discount : 0;
  }

  return dis;
};
