import { startLoading, stopLoading } from '_redux/actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_PRODUCT_TO_CART_FAILURE,
  DELETE_PRODUCT_FROM_CART,
  CARDS_BY_PRODUCT,
  CHECKOUT_CARD,
} from '_redux/types/user';
import { toast } from 'react-toastify';
import { clientApi } from 'api';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

export const addItemToCart = (eanId, ref, quantity, shop, cardId, adrcod) => async dispatch => {
  dispatch(startLoading());
  try {
    if (quantity === '0') {
      const _response = await clientApi.delete(`/carts/${cardId}`);
      const payload = trimSpacesFromObject(_response.data);

      dispatch({ type: DELETE_PRODUCT_FROM_CART, payload });
      toast('Supprimé de la carte', { className: 'toast-success' });
    } else if (cardId === -1) {
      const _response = await clientApi.post(`/carts/${cardId}`, {
        isBought: '0',
        shop,
        productEanId: eanId,
        productRef: ref,
        quantity,
        adrcod,
      });

      const response = trimSpacesFromObject(_response.data);

      dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: response });
    } else if (quantity > 0 && cardId !== -1) {
      const _response = await clientApi.put(`/carts/${cardId}`, {
        quantity,
      });

      const response = trimSpacesFromObject(_response.data);

      dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: response });
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ADD_PRODUCT_TO_CART_FAILURE, error });
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }

  dispatch(stopLoading());
};

export const deleteCart = id => async dispatch => {
  dispatch(startLoading());

  try {
    const _response = await clientApi.delete(`/carts/${id}`);
    const payload = trimSpacesFromObject(_response.data);

    dispatch({ type: DELETE_PRODUCT_FROM_CART, payload });
    toast('Supprimé de la carte', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
export const deleteAllCart = () => async dispatch => {
  dispatch(startLoading());

  try {
    const _response = await clientApi.delete('/carts');
    const payload = trimSpacesFromObject(_response.data);

    dispatch({ type: DELETE_PRODUCT_FROM_CART, payload });
    toast('Carte supprimé', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};

export const checkout = (cards, user, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const _response = await clientApi.post('/checkout-card', { cards, user });
    const payload = trimSpacesFromObject(_response.data);

    dispatch({ type: CHECKOUT_CARD, payload });
    toast('votre commande a bien été enregistrée merci pour utiliser MB-DISTRIBUTION', { className: 'toast-success' });
    history.push('/Bon-de-commande');
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};

export const getCardsByProduct = prodId => async dispatch => {
  dispatch(startLoading());
  try {
    const _response = await clientApi.get(`/carts/byProd/${prodId}`);
    const payload = trimSpacesFromObject(_response.data);

    dispatch({ type: CARDS_BY_PRODUCT, payload });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(startLoading());
};
