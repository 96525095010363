import { RESET_USER } from '_redux/types/auth';
import {
  FETCH_BC_BY_USER,
  FETCH_BON_COMMANDE,
  FETCH_BON_LIVRAISON,
  FETCH_ENTS_BY_USER,
  FETCH_FACTURES_BY_USER,
  FETCH_LIST_BON_LIVRAISON,
  FETCH_TVA_LIST,
} from '_redux/types/ents';

const initialState = {
  isPreloaded: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ENTS_BY_USER:
      return { ...state, factures: payload };
    case FETCH_LIST_BON_LIVRAISON:
      return { ...state, blList: payload };
    case FETCH_FACTURES_BY_USER:
      return { ...state, facture: payload };
    case FETCH_TVA_LIST:
      return { ...state, tvaList: payload };
    case FETCH_BON_LIVRAISON:
      return { ...state, bonlivraison: payload };
    case FETCH_BC_BY_USER:
      return { ...state, bonCommandeList: payload };
    case FETCH_BON_COMMANDE:
      return { ...state, bonCommande: payload };
    case RESET_USER:
      return {
        ...state,
        bonCommande: {},
        tvaList: [],
        facture: {},
        factures: [],
        blList: [],
        bonCommandeList: [],
        bonlivraison: {},
      };

    default:
      return state;
  }
};
