/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import writtenNumber from 'written-number';
import PropTypes from 'prop-types';
import MB_INFO from 'constants/mbInfo';
import { totalOfCarts } from 'helpers/totalOfCarts';
import TVAContent from '../TvaContent';

const FactureFooter = ({ cards, isDevi, facture, tvaList, isBl }) => {
  writtenNumber.defaults.lang = 'fr';

  return (
    <div className="footer mt-3 pt-3">
      <div className="signature-price mb-3 d-flex">
        <div className="col-4 ml-1 mr-1 " style={{ border: 'solid', height: 140 }}>
          <strong>CACHET ET SIGNATURE CLIENT :</strong>
        </div>
        <div className="col-4 ml-2" style={{ border: 'solid', height: 140 }}>
          <strong>CACHET ET SIGNATURE DIRECTION :</strong>
        </div>

        <TVAContent isDevi={isDevi} devis={cards} isBl={isBl} facture={facture} tvaList={tvaList} />
      </div>

      <div className="price-letter  rounded mb-3 mt-3" style={{ border: 'solid', width: '100%' }}>
        <p className="text-body p-2 my-auto">
          <b>
            {' '}
            Arrêté la présente {isDevi ? 'Devis' : 'facture'} à la somme de:{' '}
            {isDevi ? writtenNumber(totalOfCarts(cards?.cards) * 1000) : writtenNumber(facture && facture.ttcmt * 1000)}{' '}
            milimes
          </b>
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <h5>MB DISTRIBUTION</h5>
      </div>
      <div>
        <div className="d-flex justify-content-around flex-wrap">
          <div>{`${MB_INFO.address}`}</div>
          <div>{`${MB_INFO.state}`}</div>
          <div>{`${MB_INFO.city}`}</div>
          <div>
            Tél: <strong>{`${MB_INFO.phone}`} </strong>
          </div>
        </div>
        <div className="d-flex justify-content-around flex-wrap">
          <div>{MB_INFO.matFisc}</div>
          <div>{`${MB_INFO.RC}`}</div>
          <div>{`${MB_INFO.BANK}`}</div>
          <div style={{ wordBreak: 'break-word' }}>{`${MB_INFO.rib}`}</div>
        </div>
      </div>
      {isDevi && (
        <div className="p-5">Ce devis est valable pour 15 jours à partir de sa date de creation susmentionnée.</div>
      )}
    </div>
  );
};

FactureFooter.propTypes = {
  cards: PropTypes.any,
  facture: PropTypes.any,
  tvaList: PropTypes.any,
  isDevi: PropTypes.bool,
  isBl: PropTypes.bool,
};
export default FactureFooter;
