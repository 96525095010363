import React from 'react';
import { useDispatch } from 'react-redux';
import { createUser } from '_redux/actions/auth';
import { useHistory } from 'react-router-dom';
import CreateUserForm from 'containers/CreateUserForm';
import PropTypes from 'prop-types';

export const CreateUserPage = ({ openModalAdd, setOpenModalAdd }) => {
  const history = useHistory();
  const disptach = useDispatch();
  const handleSubmit = values => {
    disptach(createUser(values, history));
    setOpenModalAdd(!openModalAdd);
  };

  return (
    <>
      <CreateUserForm onSubmit={handleSubmit} />
    </>
  );
};
CreateUserPage.propTypes = {
  openModalAdd: PropTypes.bool.isRequired,
  setOpenModalAdd: PropTypes.func.isRequired,
};

export default CreateUserPage;
