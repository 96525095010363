import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loading from './Loading';
import products from './products';
import auth from './auth';
import userReducer from './user';
import factureBlReducer from './facturebl';

export default combineReducers({
  form,
  loading,
  products,
  userReducer,
  factureBlReducer,
  auth,
});
