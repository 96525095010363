import { clientApi, db, resetAuthToken } from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { startLoading, stopLoading } from '_redux/actions/Loading';
import { LOGIN_SUCCESS, LOGOUT, RESET_USER } from '_redux/types/auth';
import { toast } from 'react-toastify';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

export const createUser = ({ tiers, password }, history) => async dispatch => {
  dispatch(startLoading());

  try {
    const respone = await clientApi.post('/mbCli', { tiers, password });

    toast(respone, { className: 'toast-success' });
    history.push('/');
  } catch (error) {
    errorHandler(error);
    toast(
      error?.response?.data
        ? error?.response?.data
        : "un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ",
      {
        className: 'toast-danger',
      },
    );
  }
  dispatch(stopLoading());
};

export const login = ({ email, password }, history) => async dispatch => {
  dispatch(startLoading());

  try {
    const response = await clientApi.post('/mbCli/sign-in', { email, password });
    const { token } = response.data.user;
    const { ...userData } = response.data;
    const userDataTrimed = trimSpacesFromObject(userData);

    resetAuthToken(token.accessToken);
    await db.user.put({ id: userData.user.id, token, user: userDataTrimed });
    window.localStorage.setItem('id', userDataTrimed.user.id);
    delete userDataTrimed.emailActivationToken;
    delete userDataTrimed.password;
    dispatch({ type: LOGIN_SUCCESS, payload: { token, user: userDataTrimed } });
    history.push('/products?sort=asc&page={"size":16,"current":1}');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const logout = () => async dispatch => {
  resetAuthToken(null);
  window.localStorage.removeItem('id');
  try {
    dispatch({ type: LOGOUT });
    dispatch({ type: RESET_USER });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
};

export const checkLoggingStatus = () => async dispatch => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (!id) throw Error('disconnected');
    else {
      const { token, user } = await db.table('user').get({ id: parseInt(id, 10) });

      await resetAuthToken(token.refreshToken);
      const userData = await refreshToken();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: { ...token, accessToken: userData.accessToken },
          user: { ...user, user: userData.user },
        },
      });
      await db.user.put({
        id,
        token: { ...token, accessToken: userData.accessToken },
        user: { ...user, user: userData.user },
      });
      await resetAuthToken(userData.accessToken);
    }
  } catch (error) {
    dispatch({ type: RESET_USER });
  }

  dispatch(stopLoading());
};

export const refreshToken = async () => {
  try {
    const response = await clientApi.get('/mbCli/refresh-token');

    return trimSpacesFromObject(response.data);
  } catch (error) {
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });

    return false;
  }
};
