import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, deleteCart } from '_redux/actions/user';
import { isEmpty } from 'helpers/isEmptyObject';
import _ from 'lodash';
import { toast } from 'react-toastify';

const QuantityInputCarts = ({ shopAdress, productIdRef, card, qteInCard, productQuantity }) => {
  const [qteInput, setQteInput] = useState(card?.quantity);

  const isLoading = useSelector(({ loading }) => loading.loading);

  const cardIdAdd = !isEmpty(card) ? card?.id : -1;
  const dispatch = useDispatch();

  const debouncedDispatch = useCallback(_.debounce(dispatch, 1000), [dispatch]);
  const debouncedSetQte = useCallback(_.debounce(setQteInput, 10), [setQteInput]);

  const handleIncrement = () => {
    const oldValue = card && card.quantity > 0 ? card.quantity : 0;

    if (qteInput + (qteInCard - oldValue) < productQuantity) {
      debouncedSetQte(parseInt(qteInput, 10) + 1);

      debouncedDispatch(addItemToCart(productIdRef, productIdRef, qteInput + 1, shopAdress, cardIdAdd));
    } else
      toast('Vous avez dépassé la limite de ce produit ', {
        className: 'toast-danger',
      });
  };

  const handleDecrement = () => {
    if (parseInt(qteInput, 10) > 0) {
      debouncedSetQte(qteInput - 1);
      if (qteInput - 1 === 0 && card) {
        debouncedDispatch(deleteCart(card?.id));
      } else if (qteInput - 1 > 0 && card) {
        debouncedDispatch(addItemToCart(productIdRef, productIdRef, qteInput - 1, shopAdress, cardIdAdd));
      }
    }
  };

  const handleChange = value => {
    const regex = /^[0-9\b]+$/;

    if (regex.test(value)) {
      const newQteInCart = parseInt(value || '0', 10) + qteInCard - (card?.quantity || 0);

      value === '0' && dispatch(deleteCart(card?.id));

      if (newQteInCart > productQuantity) {
        if (parseInt(value, 10) - (newQteInCart - productQuantity) > 0) {
          debouncedDispatch(
            addItemToCart(
              productIdRef,
              productIdRef,
              parseInt(value, 10) - (newQteInCart - productQuantity),
              shopAdress,
              cardIdAdd,
            ),
          );
        }

        setQteInput(parseInt(value, 10) - (newQteInCart - productQuantity));
        toast('Vous avez dépassé la limite de ce produit ', {
          className: 'toast-danger',
        });
      } else {
        debouncedDispatch(addItemToCart(productIdRef, productIdRef, parseInt(value, 10), shopAdress, cardIdAdd));
        setQteInput(parseInt(value, 10));
      }
    }
  };

  return (
    <div disabled={isLoading}>
      <div className="quantity-input">
        <button
          disabled={isLoading}
          type="button"
          className="quantity-input__modifier quantity-input__modifier--right"
          onClick={handleDecrement}
        >
          -
        </button>
        <input
          disabled={isLoading}
          className="quantity-input__screen"
          min="1"
          type="text"
          value={qteInput}
          onChange={e => handleChange(e.target.value)}
        />
        <button
          disabled={isLoading}
          type="button"
          className="quantity-input__modifier quantity-input__modifier--left"
          onClick={handleIncrement}
        >
          +
        </button>
      </div>
    </div>
  );
};

QuantityInputCarts.propTypes = {
  shopAdress: PropTypes.string.isRequired,
  productIdRef: PropTypes.string.isRequired,
  card: PropTypes.object,
  qteInCard: PropTypes.number,
  productQuantity: PropTypes.number,
};

export default QuantityInputCarts;
