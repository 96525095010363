/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import writtenNumber from 'written-number';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FactureHeader from 'components/Facture/FactureHeader';
import FactureFooter from 'components/Facture/FactureFooter';

const BLContent = ({ factureRef }) => {
  writtenNumber.defaults.lang = 'fr';
  const { bonlivraison, tvaList } = useSelector(({ factureBlReducer }) => factureBlReducer);
  const maxRows = 28;

  let subTotal = 0;
  const tableRow = mouv => {
    return (
      <tr key={mouv.id} style={{ padding: '10', fontSize: 15 }}>
        <td style={{ textAlign: 'left' }}>{mouv.reference}</td>
        <td style={{ textAlign: 'left' }}>{mouv.refProduit.REFFO}</td>
        <td style={{ textAlign: 'left' }}>{mouv.title}</td>
        <td style={{ textAlign: 'right' }}>{mouv.blQuantity}</td>
        <td style={{ textAlign: 'right' }}>{mouv.pub}</td>
        <td style={{ textAlign: 'right' }}>{mouv.price}</td>
      </tr>
    );
  };
  const tableHeader = () => {
    return (
      <tr>
        <th width="130">Code à barre</th>
        <th width="140">RFO</th>
        <th>Désignation</th>
        <th width="40">Qte</th>
        <th width="80">
          PU<small>(HT)</small>
        </th>
        <th width="80">Montant</th>
      </tr>
    );
  };

  return (
    <>
      {bonlivraison && tvaList && (
        <div size="A4" className="factureContainer py-9 factureInfo ">
          <div className="ml-5 mr-5 mt-3" ref={factureRef}>
            <FactureHeader isDevi={false} facture={bonlivraison} modeTrasport={bonlivraison?.modeTrasport} isBlHeader />
            <div className="content d-flex flex-column">
              <div className="table2-container justify-content-center">
                <table cellSpacing={3} cellPadding={5} className="tableFix" rules="all">
                  {tableHeader()}
                  {bonlivraison?.mvtlbyBL?.map((mouv, index) => {
                    subTotal += parseFloat(mouv?.price);
                    if ((index + 1) % maxRows === 0) {
                      const subfinal = subTotal;

                      subTotal = 0;

                      return (
                        <>
                          <>
                            {tableRow(mouv)}
                            <tr colSpan={2} className="subtotal" style={{ textAlign: 'center' }}>
                              <td colSpan={4}>Sous-total</td>
                              <td colSpan={2}>{subfinal.toFixed(3)}</td>
                            </tr>
                            {bonlivraison?.mvtlbyBL.length > maxRows ? <div className="page-counter" /> : null}
                            <tr className="x" />
                            {tableHeader()}
                          </>
                        </>
                      );
                    }

                    return <>{tableRow(mouv)} </>;
                  })}
                </table>
              </div>
            </div>
            <FactureFooter isBl facture={bonlivraison} tvaList={tvaList} />

            {bonlivraison?.mvtlbyBL?.length > maxRows ? <div className="page-counter" /> : null}
          </div>
        </div>
      )}
    </>
  );
};

BLContent.propTypes = {
  factureRef: PropTypes.any,
};
export default BLContent;
