import { FETCH_BC_BY_USER, FETCH_TVA_LIST, FETCH_BON_COMMANDE } from '_redux/types/ents';
import { clientApi } from 'api';
import { toast } from 'react-toastify';
import { errorHandler } from 'helpers/errorHandler';
import { startLoading, stopLoading } from '../Loading';

const api = '/ent-divalto/';

export const fetchListBCUser = (tiers, picod) => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await clientApi.get(`${api}bcommandes/${tiers}/${picod}`);

    dispatch({ type: FETCH_BC_BY_USER, payload: response.data.entsList });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
export const fetchBcById = (pino, picod, tiers) => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await clientApi.get(`${api}bcById/${pino}/${picod}/${tiers}`);

    dispatch({ type: FETCH_TVA_LIST, payload: response.data.tvaList });
    dispatch({ type: FETCH_BON_COMMANDE, payload: response.data.entDivalto });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
