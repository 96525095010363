import React from 'react';
import ProductCard from 'components/ProductCard';
import { getImageUrl } from 'helpers/getImageUrl';
import { toTitleCase } from 'helpers/toTitleCase';
import Pagination from 'components/Pagination';
import { RED_TAG, GREEN_TAG } from 'constants/tags';
import Chips from 'containers/Chips';
import useElastic from 'hooks/useElastic';
import ProductsListHeader from './ProductsListHeader';

const ProductsContainer = () => {
  const tableOfRef = [];
  const { hits } = useElastic();

  return (
    <>
      <ProductsListHeader />
      <Chips />
      <div className="list-unstyled">
        {hits &&
          hits.map(article => {
            return (
              <ProductCard
                key={article?.id?.raw}
                reference={article?.reference?.raw}
                eanId={article?.eanid ? article?.eanid?.raw : article?._meta?.id}
                idRef={article?.id?.raw}
                price={article?.price?.raw}
                unit="TND"
                discount={article?.discountart?.raw}
                redTag={RED_TAG}
                greenTag={GREEN_TAG}
                quantity={article?.quantity?.raw}
                buttonText="Acheter"
                buttonExistText=" 🛒 "
                title={toTitleCase(article?.title?.snippet)}
                description={article?.description?.snippet}
                isAddedToCart={tableOfRef.includes(article?.id?.raw)}
                image={`${getImageUrl(article?.id?.raw, 0)}`}
              />
            );
          })}
      </div>

      <Pagination />
    </>
  );
};

export default ProductsContainer;
