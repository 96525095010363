import React, { useEffect } from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useDispatch, useSelector } from 'react-redux';
import ListFactures from 'containers/ListFactures';
import PICOD_TYPE from 'constants/ent';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { fetchEntsUser } from '_redux/actions/factureBl/facture';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CircularProgress } from '@material-ui/core';

const FacturePage = () => {
  const { factures } = useSelector(({ factureBlReducer }) => factureBlReducer);

  const loggedUser = useSelector(({ auth }) => auth?.user?.user);
  const dispatch = useDispatch();
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    dispatch(fetchEntsUser(trimSpacesFromObject(loggedUser?.tiers), PICOD_TYPE.FACTURE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView>
      {isLoading ? (
        <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
          <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
        </div>
      ) : (
        <div className="container space-top-1 space-bottom-2 space-bottom-lg-3">
          <div className="row">
            {factures && loggedUser && (
              <>
                <div className="container space-1 space-md-2">
                  <div className="row">
                    <ListFactures factures={factures} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </SafeAreaView>
  );
};

export default FacturePage;
