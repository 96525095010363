import SafeAreaView from 'components/SafeAreaView';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import FactureContent from 'components/Facture/FactureContent';

const Devis = cards => {
  const componentRef = useRef();

  return (
    <SafeAreaView>
      {cards && (
        <>
          <div className="container overflow-auto pt-4">
            <FactureContent isDevi cards={cards} factureRef={componentRef} />
          </div>
          <ReactToPrint
            trigger={() => (
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-soft-dark mt-6" type="button">
                  Imprimer 
                  {' '}
                  <i className="fa fa-print" />
                </button>
              </div>
            )}
            content={() => componentRef.current}
          />
        </>
      )}
    </SafeAreaView>
  );
};

Devis.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  cards: PropTypes.array,
};
export default Devis;
