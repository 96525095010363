import { FETCH_CSV, FETCH_PRODUCTS, FETCH_EXCEL, UPDATE_ELASTIC_MANUEL } from '_redux/types/products';
import { errorHandler } from 'helpers/errorHandler';
import { esApi, clientApi } from 'api';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { toast } from 'react-toastify';
import { ADD_PRODUCT_TO_CART_SUCCESS } from '_redux/types/user';
import FileDownload from 'js-file-download';
import { startLoading, stopLoading } from '../Loading';

export const fetchProducts = query => async dispatch => {
  dispatch(startLoading());
  try {
    const _response = await esApi.post(`/search.json`, query);
    const response = _response.data;

    dispatch({ type: FETCH_PRODUCTS, payload: trimSpacesFromObject(response) });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};

export const exportCSV = (brand, category, shops) => async dispatch => {
  dispatch(startLoading());
  toast('veuillez patienter pendant que nous traitons votre fichier', { className: 'toast-primery' });

  try {
    const response = await clientApi.post(`/download`, { brand, category, shops }, { responseType: 'blob' });

    FileDownload(response.data, 'Catalogue.xlsx');
    dispatch({ type: FETCH_EXCEL, payload: response.data });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
export const importCartsFromCSV = csvRows => async dispatch => {
  dispatch(startLoading());

  toast('veuillez patienter pendant que nous traitons votre fichier', { className: 'toast-primery' });

  try {
    const response = await clientApi.post(`/importDataFromCsv`, {
      csvRows,
    });

    dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: response.data.carts });
    response.data.checkQty > 0
      ? toast(
          `Panier importé depuis Excel mais vous atteignez la limite de quantité pour ${response.data.checkQty} produits nous remplaçons la valeur que vous mentionnez dans le fichier par la quantité maximale que vous pouvez acheter pour l'instant `,
          { className: 'toast-success' },
        )
      : toast('Panier importé depuis Excel', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    toast(
      error?.response?.data
        ? error?.response?.data
        : "un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ",
      {
        className: 'toast-danger',
      },
    );
  }
  dispatch(stopLoading());
};
export const cleanCSV = () => async dispatch => {
  dispatch(startLoading());

  try {
    dispatch({ type: FETCH_CSV, payload: undefined });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};

export const importPromotions = csvRows => async dispatch => {
  dispatch(startLoading());

  toast('veuillez patienter pendant que nous traitons votre fichier', { className: 'toast-primery' });

  try {
    const response = await clientApi.post(
      `/importPromotins`,
      {
        csvRows,
      },
      { responseType: 'blob' },
    );

    toast('promotions importé depuis Excel', { className: 'toast-success' });
    FileDownload(response.data, 'failedPromotions.xlsx');
  } catch (error) {
    toast("quelque chose s'est mal passé, essayez à nouveau", { className: 'toast-danger' });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateElastic = history => async dispatch => {
  dispatch(startLoading());
  toast('veuillez patienter pendant que nous traitons votre demande', { className: 'toast-primery' });
  try {
    const response = await clientApi.get(`/update-elastic`);

    dispatch({ type: UPDATE_ELASTIC_MANUEL });
    toast(response.data, { className: 'toast-success' });
    history.push('/');
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
