import { SET_USER } from '_redux/types/auth';

const initialState = {
  user: null,
  isPreloaded: false,
  error: null,
};

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case SET_USER:
      return { ...state, user: payload };

    default:
      return state;
  }
};
