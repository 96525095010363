/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import SafeAreaView from 'components/SafeAreaView';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { useRouteMatch } from 'react-router-dom';
import PICOD_TYPE from 'constants/ent';
import FactureContent from 'components/Facture/FactureContent';
import { fetchFacturesUser } from '_redux/actions/factureBl/facture';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CircularProgress } from '@material-ui/core';

const FactureDetailPage = () => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const loggedUser = useSelector(({ auth }) => auth?.user?.user);
  const isLoading = useSelector(({ loading }) => loading.loading);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(fetchFacturesUser(params.id, PICOD_TYPE.FACTURE, trimSpacesFromObject(loggedUser?.tiers)));
  }, []);

  return (
    <SafeAreaView>
      {loggedUser && (
        <div>
          {isLoading ? (
            <div className="d-flex justify-content-center" style={{ position: 'fixed', width, height }}>
              <CircularProgress size={70} color="inherit" className="my-auto" disableShrink />
            </div>
          ) : (
            <>
              <div className="container overflow-auto pt-4 mt-5">
                <FactureContent factureRef={componentRef} />
              </div>
              <ReactToPrint
                trigger={() => (
                  <div className="col-12 d-flex justify-content-center">
                    <button className="btn btn-soft-dark mt-6  mb-6" type="button">
                      Imprimer la facture ↓ 
                      {' '}
                      <i className="fa fa-print" />
                    </button>
                  </div>
                )}
                content={() => componentRef.current}
              />
            </>
          )}
        </div>
      )}
    </SafeAreaView>
  );
};

export default FactureDetailPage;
