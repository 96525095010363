/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import writtenNumber from 'written-number';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FactureHeader from 'components/Facture/FactureHeader';
import BCFooter from '../BCFooter';

const BCContent = ({ factureRef }) => {
  writtenNumber.defaults.lang = 'fr';
  const { bonCommande, tvaList } = useSelector(({ factureBlReducer }) => factureBlReducer);
  const maxRows = 28;

  let subTotal = 0;
  const tableRow = mouv => {
    return (
      <tr key={mouv.id} style={{ padding: '10', fontSize: 15 }}>
        <td style={{ textAlign: 'left' }}>{mouv?.reference}</td>
        <td style={{ textAlign: 'left' }}>{mouv?.refProduit?.REFFO}</td>
        <td style={{ textAlign: 'left' }}>{mouv?.title}</td>
        <td style={{ textAlign: 'right' }}>{mouv?.bcQuantity}</td>
        <td style={{ textAlign: 'right' }}>{mouv?.pub}</td>
        <td style={{ textAlign: 'right' }}>{mouv?.discount}</td>
        <td style={{ textAlign: 'right' }}>{mouv?.price}</td>
      </tr>
    );
  };
  const tableHeader = () => {
    return (
      <tr>
        <th width="130">Code à barre</th>
        <th width="140">RFO</th>
        <th>Désignation</th>
        <th width="40">Qte</th>
        <th width="80">
          PU<small>(HT)</small>
        </th>
        <th width="40">R%</th>
        <th width="80">Montant</th>
      </tr>
    );
  };

  return (
    <>
      {bonCommande && tvaList && (
        <div size="A4" className="factureContainer py-9 factureInfo ">
          <div className="ml-5 mr-5 mt-3" ref={factureRef}>
            <FactureHeader isDevi={false} facture={bonCommande} modeTrasport={bonCommande?.modeTrasport} isBC />
            <div className="content d-flex flex-column">
              <div className="table2-container justify-content-center">
                <table cellSpacing={3} cellPadding={5} className="tableFix" rules="all">
                  <tbody>
                    {tableHeader()}
                    {bonCommande?.mvtlbyBC?.map((mouv, index) => {
                      subTotal += parseFloat(mouv?.price);
                      if ((index + 1) % maxRows === 0) {
                        const subfinal = subTotal;

                        subTotal = 0;

                        return (
                          <>
                            {tableRow(mouv)}
                            <tr key={mouv.id + mouv.id} className="subtotal" style={{ textAlign: 'center' }}>
                              <td colSpan={4}>Sous-total</td>
                              <td colSpan={3}>{subfinal.toFixed(3)}</td>
                            </tr>
                            {bonCommande?.mvtlbyBC?.length > maxRows ? <tr className="page-counter" /> : null}
                            <tr className="x" />
                            {tableHeader()}
                          </>
                        );
                      }

                      return <>{tableRow(mouv)} </>;
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="footer mt-3 pt-3">
              <BCFooter bonCommande={bonCommande} tvaList={tvaList} />
            </div>

            {bonCommande?.mvtlbyBC?.length > maxRows ? <div className="page-counter" /> : null}
          </div>
        </div>
      )}
    </>
  );
};

BCContent.propTypes = {
  factureRef: PropTypes.any,
};
export default BCContent;
