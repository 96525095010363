import _ from 'lodash';

export const groupCardsByShop = cards => {
  let filtredCards = [];

  cards.forEach(card => {
    filtredCards.push({ shopName: card?.shop, cardsByShop: [] });
  });

  filtredCards = _.uniqBy(filtredCards, 'shopName');

  cards.forEach(card => {
    filtredCards.forEach(filtredCard => {
      if (card?.shop === filtredCard?.shopName) {
        filtredCard.cardsByShop.push(card);
      }
    });
  });

  return filtredCards;
};
