import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FactureItem from 'components/Facture/FactureItem';
import SearchBannerEnt from 'components/SearchBannerEnt';
import moment from 'moment';

const ListFactures = ({ factures }) => {
  const [sortedFacturesData, setSortedFacturesData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterByType, setFilterByType] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (!factures?.length) return;
    const searchRegex = searchValue && new RegExp(`${searchValue}`, 'gi');

    const result = factures.filter(
      ent =>
        (!searchRegex || searchRegex.test(ent.pino)) &&
        (!filterByType || ent.etat === filterByType) &&
        ((!date.endDate && !date.startDate) ||
          moment(ent.creation).isBetween(date.startDate, date.endDate, 'days', '[]')),
    );

    setSortedFacturesData(result);
  }, [searchValue, filterByType, date, factures]);

  return (
    <div className="col-12">
      <h1 className="pb-5">Liste des factures</h1>
      <div className="justify-content-between align-items-end border-bottom pb-3 mb-7">
        <SearchBannerEnt
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterByType={filterByType}
          setFilterByType={setFilterByType}
          isFacture
          {...{ setDate, date, setFocusedInput, focusedInput }}
        />
      </div>
      <table className="col-12">
        <thead>
          <tr>
            <td className="text-center">
              <b>No de la Facture</b>
            </td>
            <td className="text-center">
              <b>Date de la Facture</b>
            </td>
            <td>
              <b>Bon de livraison</b>
            </td>
            <td>
              <b>Montant de la Facture (TTC)</b>
            </td>
          </tr>
        </thead>
      </table>

      {sortedFacturesData &&
        sortedFacturesData?.map(facture => {
          return <FactureItem key={facture?.id} facture={facture} />;
        })}
    </div>
  );
};

ListFactures.propTypes = {
  factures: PropTypes.array,
};

export default ListFactures;
