/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import { importCartsFromCSV, importPromotions } from '_redux/actions/products';
import { toast } from 'react-toastify';

const ExcelToJson = ({ promo, openModalAddPromo, setOpenModalAddPromo }) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const filePathset = e => {
    e.stopPropagation();
    e.preventDefault();
    const tmpFile = e.target.files[0];

    setFile(tmpFile);
  };

  const readFile = () => {
    const f = file;

    const reader = new FileReader();

    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      openModalAddPromo && setOpenModalAddPromo(!openModalAddPromo);
      promo ? dispatch(importPromotions(convertToJson(data))) : dispatch(importCartsFromCSV(convertToJson(data)));
    };
    reader.readAsBinaryString(f);
  };

  const convertToJson = csv => {
    const lines = csv.split('\n');

    const result = [];

    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      const obj = {};

      const currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result;
  };

  return (
    <div className="mt-5">
      <div className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          {promo ? 'Importer votre excel  promotion' : 'Importer votre excel panier'}
        </h1>
      </div>
      <div className="d-flex flex-column justify-content-center my-3">
        <div className="file-attachment-btn mb-2" style={{ height: '50px' }}>
          <div className="row mb-3">
            <label htmlFor="files" className="btn">
              <i className="fas fa-upload" />
              {' '}
              click ici pour Déposez votre fichier
            </label>
          </div>
          <input id="files" style={{ visibility: 'hidden' }} type="file" onChange={filePathset} />
        </div>

        <div style={{ zIndex: 1 }} className={promo && 'col-6'}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              !file ? toast(`veuillez d'abord déposer votre fichier `, { className: 'toast-danger' }) : readFile();
            }}
          >
            {!file ? 'Selectionner votre fichier avant' : 'Importer le ficher'}
          </button>
        </div>
      </div>
    </div>
  );
};

ExcelToJson.propTypes = {
  promo: PropTypes.bool,
  openModalAddPromo: PropTypes.bool,
  setOpenModalAddPromo: PropTypes.func,
};
export default ExcelToJson;
