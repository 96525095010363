import React from 'react';
import SEO from 'components/SEO';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '_redux/actions/user';
import PasswordForm from 'containers/PasswordForm';
import PropTypes from 'prop-types';

const UpdatePassword = ({ setOpenPass, openPass }) => {
  const disptach = useDispatch();
  const loggedUser = useSelector(({ auth }) => auth?.user?.user);

  const handleSubmit = values => {
    const data = { values, userId: loggedUser.id };

    disptach(updatePassword(data));
    setOpenPass(!openPass);
  };

  return (
    <>
      <SEO title="Login page" description="Se connecter à MB-Distribution" />
      <main id="content" role="main">
        <div className="d-flex justify-content-center pt-5">
          <PasswordForm onSubmit={handleSubmit} />
        </div>
      </main>
    </>
  );
};

UpdatePassword.propTypes = {
  setOpenPass: PropTypes.func.isRequired,
  openPass: PropTypes.bool.isRequired,
};
export default UpdatePassword;
