/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { priceNoZeros } from 'helpers/priceNoZeros';
import { totalOfCarts } from 'helpers/totalOfCarts';
import { useSelector } from 'react-redux';

const TVAContent = ({ facture, devis, tvaList, isBl, isDevi }) => {
  const totalTVA = {
    '0': 0,
    '7': 0,
    '13': 0,
    '19': 0,
  };
  const loggedUser = useSelector(({ auth }) => auth?.user?.user?.user);

  if (isBl) {
    facture &&
      facture?.mvtlbyBL?.forEach(mouv => {
        const TVA = tvaList
          .filter(data => trimSpacesFromObject(data.tvaTie) === trimSpacesFromObject(facture?.tvaTie))
          .filter(data => trimSpacesFromObject(data.tvaArt) === trimSpacesFromObject(mouv?.tvaArt));
        // price prixprod

        totalTVA[TVA[0]?.tvaP] += (mouv.price * TVA[0]?.tvaP) / 100;
      });
  } else if (devis) {
    devis &&
      devis?.cards?.forEach(card => {
        const TVA = tvaList
          .filter(data => trimSpacesFromObject(data.tvaTie) === trimSpacesFromObject(loggedUser?.TVATIE))
          .filter(data => trimSpacesFromObject(data?.tvaArt) === trimSpacesFromObject(card?.product?.tvaart));

        totalTVA[TVA[0]?.tvaP] += (card?.product?.price * TVA[0]?.tvaP) / 100;
      });
  } else {
    facture &&
      facture?.mvtls?.forEach(mouv => {
        const TVA = tvaList
          .filter(data => trimSpacesFromObject(data.tvaTie) === trimSpacesFromObject(facture?.tvaTie))
          .filter(data => trimSpacesFromObject(data.tvaArt) === trimSpacesFromObject(mouv?.mouvDivalto?.tvaArt));

        totalTVA[TVA[0]?.tvaP] += (mouv.mouvDivalto?.price * TVA[0]?.tvaP) / 100;
      });
  }

  return (
    tvaList && (
      <div className="col-4  rounded">
        <table cellPadding="2" style={{ border: 'solid', width: '100%' }}>
          <thead>
            <tr className="text-center">
              <th style={{ width: '5%' }}>.</th>
              <th style={{ width: '20%' }}>Base T.V.A</th>
              <th style={{ width: '10%' }}>Taux</th>
              <th style={{ width: '30%' }} className="text-center">
                Montant T.V.A
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <small>0</small>
              </td>
              {devis ? (
                <td>{priceNoZeros(totalOfCarts(devis?.cards), 'TND', true)}</td>
              ) : (
                <td>{totalTVA[0] > 0 && (facture?.ttcmt - totalTVA[0]).toFixed(3)}</td>
              )}
              <td>0,00</td>
              <td />
            </tr>
            <tr>
              <td>
                <small>1</small>
              </td>
              {devis ? (
                <td>{priceNoZeros(totalOfCarts(devis?.cards), 'TND', true)}</td>
              ) : (
                <td>{totalTVA[7] > 0 && (facture?.ttcmt - totalTVA[7]).toFixed(3)}</td>
              )}
              <td>7,00</td>
              <td />
            </tr>
            <tr>
              <td>
                <small>2</small>
              </td>
              {devis ? (
                <td>{priceNoZeros(totalOfCarts(devis?.cards), 'TND', true)}</td>
              ) : (
                <td>{totalTVA[13] > 0 && (facture?.ttcmt - totalTVA[13]).toFixed(3)}</td>
              )}
              <td>13,00</td>
              <td className="text-center">{totalTVA[13] > 0 && (facture?.ttcmt - totalTVA[13]).toFixed(2)}</td>
            </tr>
            <tr>
              <td>
                <small>3</small>
              </td>
              {devis ? (
                <td>{priceNoZeros(totalOfCarts(devis?.cards), 'TND', true)}</td>
              ) : (
                <td>{totalTVA[19] > 0 && (facture?.ttcmt - totalTVA[19]).toFixed(3)}</td>
              )}
              <td>19,00</td>
              <td className="text-center">{totalTVA[19] > 0 && totalTVA[19].toFixed(3)}</td>
            </tr>

            {!isBl && !isDevi && (
              <tr>
                <td className="flex-d text-sm-left" colSpan="2">
                  <small>TIMBRE FISCALE</small>
                </td>
                <td />
                <td className="text-center">0,600</td>
              </tr>
            )}
            {facture && (
              <tr>
                <td className="text-left" colSpan="2">
                  <b>TOTAL H.T</b>
                </td>
                <td colSpan="2">
                  {`${facture?.totalht?.toFixed(3)}`}
                  {' TND'}
                </td>
              </tr>
            )}
            <tr>
              <td className="text-left" colSpan="2">
                <b>TOTAL T.T.C</b>
              </td>

              {isDevi ? (
                <td colSpan="2">
                  {totalTVA[0] > 0 && priceNoZeros(totalOfCarts(devis?.cards) + totalTVA[0], 'TND')}
                  {totalTVA[7] > 0 && priceNoZeros(totalOfCarts(devis?.cards) + totalTVA[7], 'TND')}
                  {totalTVA[13] > 0 && priceNoZeros(totalOfCarts(devis?.cards) + totalTVA[13], 'TND')}
                  {totalTVA[19] > 0 && priceNoZeros(totalOfCarts(devis?.cards) + totalTVA[19], 'TND')}
                </td>
              ) : (
                <td colSpan="2">{`${facture?.ttcmt?.toFixed(3)} TND`}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
};

TVAContent.propTypes = {
  facture: PropTypes.object,
  tvaList: PropTypes.array,
  devis: PropTypes.object,
  isBl: PropTypes.bool,
  isDevi: PropTypes.bool,
};
export default TVAContent;
