/* eslint-disable no-extra-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

const BLItem = ({ BL }) => {
  return (
    BL && (
      <>
        <Link className="card card-frame py-3 px-4 mb-2 " to={`/bon-de-livraison/${BL.pino}`}>
          <div className="row align-items-sm-center">
            <span className="col-4 text-dark">{BL.pino}</span>
            <span className="col-4 text-body">{moment(BL.creation).format('DD/MM/YYYY')}</span>
            <span className="col-4 text-right">
              {`${BL.ttcmt} TND`}
              <i className="fas fa-angle-right fa-sm ml-1" />
            </span>
          </div>
        </Link>
      </>
    )
  );
};

BLItem.propTypes = {
  BL: PropTypes.object.isRequired,
};
export default BLItem;
