import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

const ForgetPasswordForm = ({ handleSubmit }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div style={{ textAlign: 'center' }} className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          Bienvenue sur
          <span className="font-weight-semi-bold"> MB-DISTRIBUTION</span>
        </h1>
        <p>S.V.P saisir votre adresse mail</p>
      </div>

      <InputField
        type="email"
        name="email"
        label="Adresse Email"
        placeholder="Adresse Email"
        validate={[Validators.email, Validators.required]}
      />

      <div className="d-flex justify-content-center pb-2">
        <Link to="/login">connecter</Link>
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary mb-1" type="submit">
          Envoyer moi un email
        </button>
      </div>
    </form>
  );
};

ForgetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'ForgetPasswordForm' })(ForgetPasswordForm);
