import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

const LoginForm = ({ handleSubmit }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div style={{ textAlign: 'center' }} className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          Bienvenue sur
          <span className="font-weight-semi-bold"> MB-DISTRIBUTION</span>
        </h1>
        <p>Remplissez le formulaire pour commencer</p>
      </div>

      <InputField
        type="email"
        name="email"
        label="Adresse Email"
        placeholder="Adresse Email"
        validate={[Validators.email, Validators.required]}
      />

      <InputField
        type="password"
        name="password"
        label="Mot de passe"
        placeholder="********"
        forgotPasswordLink="/"
        validate={[Validators.required]}
      />
      <div className="d-flex justify-content-center pb-2">
        <Link to="/mot-de-passe-oublié">Mot de passe oublié ?</Link>
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary mb-1" type="submit">
          Commencer
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'loginForm' })(LoginForm);
