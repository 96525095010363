import { UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE } from '_redux/types/user';

import { toast } from 'react-toastify';
import { clientApi } from 'api';
import { startLoading, stopLoading } from '../Loading';

export * from './cart';

export const updatePassword = data => async dispatch => {
  dispatch(startLoading());

  try {
    await clientApi.put('/mbCli/update-password', data);
    toast('Votre mot de passe a été modifier avec succès', { className: 'toast-success' });
    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE, error });
    toast(
      error?.response?.data
        ? error?.response?.data
        : "un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ",
      {
        className: 'toast-danger',
      },
    );
  }
  dispatch(stopLoading());
};

export const reqResetPassword = data => async dispatch => {
  dispatch(startLoading());
  try {
    const res = await clientApi.post('/mbCli/reset-password', data);

    toast(res?.data, { className: 'toast-success' });
  } catch (error) {
    toast(
      error?.response?.data
        ? error?.response?.data
        : "un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ",
      {
        className: 'toast-danger',
      },
    );
  }
  dispatch(stopLoading());
};

export const resetPassword = (resetPasswordData, onSuccess) => async dispatch => {
  dispatch(startLoading());

  try {
    await clientApi.post('/mbCli/reset', resetPasswordData);

    toast('Votre mot de passe a été modifier avec succès', { className: 'toast-success' });
    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    // eslint-disable-next-line no-unused-expressions
    onSuccess?.();
  } catch (error) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE, error });
    toast(
      error?.response?.data
        ? error?.response?.data
        : "un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ",
      {
        className: 'toast-danger',
      },
    );
  }
  dispatch(stopLoading());
};
