import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';

const PasswordForm = ({ handleSubmit }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div style={{ textAlign: 'center' }} className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          mettre à jour votre
          <span className="font-weight-semi-bold"> mot de passe</span>
        </h1>
        <p>Remplissez le formulaire pour commencer</p>
      </div>

      <InputField
        type="password"
        name="currentPassword"
        label="Votre mot de passe actuel *"
        placeholder="********"
        validate={[Validators.required]}
      />

      <InputField
        type="password"
        name="password"
        label="Nouveau Mot de passe *"
        placeholder="********"
        validate={[Validators.required, Validators.min8]}
      />
      <InputField
        type="password"
        name="confirmPassword"
        label="Confirmez votre nouveau mot de passe *"
        placeholder="********"
        validate={[Validators.required, Validators.min8, Validators.passwordConfirm]}
      />

      <div className="row align-items-center mb-5 d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            mettre à jour
          </button>
        </div>
      </div>
    </form>
  );
};

PasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'PasswordForm' })(PasswordForm);
