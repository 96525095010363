import { FETCH_BON_LIVRAISON, FETCH_TVA_LIST, FETCH_LIST_BON_LIVRAISON } from '_redux/types/ents';
import { clientApi } from 'api';
import { toast } from 'react-toastify';
import { errorHandler } from 'helpers/errorHandler';
import { startLoading, stopLoading } from '../Loading';

const api = '/ent-divalto/';

export const fetchBlList = (tiers, picod) => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await clientApi.get(`${api}blList/${tiers}/${picod}`);

    dispatch({ type: FETCH_LIST_BON_LIVRAISON, payload: response.data.entsList });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
export const fetchBLUser = (pino, picod, tiers) => async dispatch => {
  dispatch(startLoading());

  try {
    const response = await clientApi.get(`${api}BonLivraison/${pino}/${picod}/${tiers}`);

    dispatch({ type: FETCH_BON_LIVRAISON, payload: response.data.entDivalto });

    dispatch({ type: FETCH_TVA_LIST, payload: response.data.tvaList });
  } catch (error) {
    errorHandler(error);
    toast("un problème est survenu, veuillez patienter un moment et essayez d'actualiser la page ", {
      className: 'toast-danger',
    });
  }
  dispatch(stopLoading());
};
