/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useElastic from 'hooks/useElastic';
import { useCreateUrl } from 'hooks/useCreateUrl';

const Page = ({ isPrevious, isNext, page, isDisabled, refinePagination, isActive, link }) => {
  let text = page;

  if (isNext) text = '>';
  else if (isPrevious) text = '<';
  else text = page;

  return (
    <li className={`page-item ${isDisabled ? 'disabled' : ''} ${isActive ? 'active' : ''}`}>
      <Link to={link} className="page-link" onClick={refinePagination}>
        {text}
      </Link>
    </li>
  );
};

Page.propTypes = {
  isPrevious: PropTypes?.bool,
  isNext: PropTypes?.bool,
  page: PropTypes?.number,
  isDisabled: PropTypes?.bool,
  isActive: PropTypes?.bool,
  refinePagination: PropTypes?.func,
  link: PropTypes?.string,
};
const Pagination = () => {
  const { meta } = useElastic();

  let firstPage = 1;

  if (meta?.page?.current > 2) firstPage = meta?.page?.current - 2;
  if (meta?.page?.current === meta?.page?.total_pages && meta?.page?.total_pages >= 5) firstPage -= 2;
  else if (meta?.page?.current === meta?.page?.total_pages - 1 && meta?.page?.total_pages >= 5) firstPage -= 1;
  const createUrl = useCreateUrl();

  return (
    <>
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <Page
            isPrevious
            link={createUrl({ page: JSON.stringify({ size: 16, current: meta?.page?.current - 1 }) })}
            // refinePagination={() => refinePagination(meta?.page?.current - 1)}
            isDisabled={meta?.page?.current === 1}
          />
          {Array(meta?.page?.total_pages > 5 ? 5 : meta?.page?.total_pages)
            ?.fill(0)
            ?.map((_, index) => {
              const ind = index;

              return (
                <Page
                  key={firstPage + ind}
                  link={createUrl({ page: JSON.stringify({ size: 16, current: firstPage + index }) })}
                  page={firstPage + index}
                  isActive={meta?.page?.current === firstPage + index}
                  // refinePagination={e => {
                  //   refinePagination(firstPage + index);
                  // }}
                  isDisabled={index + firstPage > meta?.page?.total_pages}
                />
              );
            })}
          <Page
            link={createUrl({ page: JSON.stringify({ size: 16, current: meta?.page?.current + 1 }) })}
            isNext
            // refinePagination={() => refinePagination(meta?.page?.current + 1)}
            isDisabled={meta?.page?.current === meta?.page?.total_pages}
          />
        </ul>
      </div>
    </>
  );
};

Pagination.propTypes = {};

export default Pagination;
