import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_USER,
  SIGN_UP_FAILED,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  UPDATE_TOKEN,
} from '_redux/types/auth';
import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  CHECKOUT_CARD,
  DELETE_PRODUCT_FROM_CART,
  CARDS_BY_PRODUCT,
} from '_redux/types/user';

export const authInitialState = {
  loaded: false,
  isLogged: false,
  token: false,
};

export default (state = authInitialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return { ...state, ...payload, isLogged: true, loaded: true };
    case LOGIN_FAILED:
      return { ...state, ...payload, isLogged: false, loaded: true };
    case SIGN_UP_START:
      return { ...state, loaded: false, loading: true };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loaded: true,
        isLogged: true,
        token: payload,
      };
    case SIGN_UP_FAILED:
      return { ...state, loaded: false };
    case ADD_PRODUCT_TO_CART_SUCCESS:
      return { ...state, user: { ...state.user, user: { ...state.user?.user, carts: payload } } };
    case CHECKOUT_CARD:
      return { ...state, user: { ...state.user, user: { ...state.user?.user, carts: payload } } };
    case CARDS_BY_PRODUCT:
      return { ...state, user: { ...state.user, user: { ...state.user?.user, carts: payload } } };
    case DELETE_PRODUCT_FROM_CART:
      return { ...state, user: { ...state.user, user: { ...state.user?.user, carts: payload } } };
    case LOGOUT:
      return { ...state, user: {}, token: {}, loaded: true, isLogged: false };
    case RESET_USER:
      return { ...state, user: {}, token: {}, loaded: true, isLogged: false };
    case UPDATE_TOKEN:
      return { ...state, token: { ...state.token, accessToken: payload } };
    default:
      return state;
  }
};
