import { discount } from './discount';
import { priceNoZeros } from './priceNoZeros';
import { trimSpacesFromObject } from './trimSpacesFromObject';

export const getTtcProduct = (product, tvaList, loggedUser, qte, noCurrency) => {
  const totalTVA = {
    '0': 0,
    '7': 0,
    '13': 0,
    '19': 0,
  };

  const TVA = tvaList
    .filter(data => trimSpacesFromObject(data?.tvaTie) === trimSpacesFromObject(loggedUser?.TVATIE))
    .filter(
      data =>
        trimSpacesFromObject(data?.tvaArt) ===
        trimSpacesFromObject(product?.tvaart ? product?.tvaart : product?.tvaArt),
    );

  if (discount(product?.articlesDiscount) !== 0) {
    totalTVA[TVA[0]?.tvaP] +=
      ((product?.price - (product?.price * discount(product?.articlesDiscount)) / 100) * TVA[0]?.tvaP) / 100;
  } else {
    totalTVA[TVA[0]?.tvaP] += (product?.price * TVA[0]?.tvaP) / 100;
  }

  if (totalTVA[0] > 0) return priceNoZeros((product?.price + totalTVA[0]) * qte, 'TND', noCurrency);
  if (totalTVA[7] > 0) return priceNoZeros((product?.price + totalTVA[7]) * qte, 'TND', noCurrency);
  if (totalTVA[13] > 0) return priceNoZeros((product?.price + totalTVA[13]) * qte, 'TND', noCurrency);
  if (totalTVA[19] > 0) return priceNoZeros((product?.price + totalTVA[19]) * qte, 'TND', noCurrency);

  return 0;
};
