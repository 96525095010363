/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

const BCFooter = ({ bonCommande, tvaList }) => {
  const totalTVA = {
    '0': 0,
    '7': 0,
    '13': 0,
    '19': 0,
  };

  bonCommande?.mvtlbyBC?.forEach(mouv => {
    const TVA = tvaList
      .filter(data => trimSpacesFromObject(data.tvaTie) === trimSpacesFromObject(bonCommande.tvaTie))
      .filter(data => trimSpacesFromObject(data.tvaArt) === trimSpacesFromObject(mouv.tvaArt));

    totalTVA[TVA[0].tvaP] += (mouv.price * TVA[0].tvaP) / 100;
  });

  return (
    tvaList && (
      <>
        <div className="col-12" style={{ border: 'solid' }}>
          <table style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              <tr className="text-center">
                <th width="10%">.</th>
                <th width="20%">Base T.V.A</th>
                <th>Taux</th>
                <th>Montant T.V.A</th>
                <th width="40%">Total H.T</th>
              </tr>
            </thead>
            <tr>
              <td>
                <small>0</small>
              </td>
              <td className="text-center">{totalTVA[0] > 0 ? (bonCommande.ttcmt - totalTVA[19]).toFixed(3) : null}</td>
              <td className="text-center">0,00</td>
              <td />
              <td className="text-center">
                {`${bonCommande.totalht.toFixed(3)}`}
                {' TND'}
              </td>
            </tr>
            <tr>
              <td>
                <small>1</small>
              </td>
              <td className="text-center">{totalTVA[7] > 0 ? (bonCommande.ttcmt - totalTVA[7]).toFixed(2) : null}</td>
              <td className="text-center">7,00</td>
              <td />
            </tr>
            <tr>
              <td>
                <small>2</small>
              </td>
              <td className="text-center">{totalTVA[13] > 0 ? (bonCommande.ttcmt - totalTVA[13]).toFixed(3) : null}</td>
              <td className="text-center">13,00</td>
              <td className="text-center">{totalTVA[13] > 0 ? (bonCommande.ttcmt - totalTVA[13]).toFixed(2) : null}</td>
              <th className="text-center">Total T.T.C</th>
            </tr>
            <tr>
              <td>
                <small>3</small>
              </td>
              <td className="text-center">{totalTVA[19] > 0 ? (bonCommande.ttcmt - totalTVA[19]).toFixed(3) : null}</td>
              <td className="text-center">19,00</td>
              <td style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                {totalTVA[19] > 0 ? totalTVA[19].toFixed(3) : null}
              </td>
              <td style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                {`${bonCommande.ttcmt.toFixed(3)}`}
                {' TND'}
              </td>
            </tr>
          </table>
        </div>
      </>
    )
  );
};

BCFooter.propTypes = {
  bonCommande: PropTypes.object.isRequired,
  tvaList: PropTypes.array,
};
export default BCFooter;
