/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import LoaderComponent from 'components/Loader';
import CustomClearRefinementsBtutton from 'components/ClearRefinementsButton';
import { useDispatch, useSelector } from 'react-redux';
import { exportCSV } from '_redux/actions/products';
import useBrandHeroes from 'hooks/useBrandHeroes';
// import useCategories from 'hook/useCategories';
import PropTypes from 'prop-types';
import FilterBrand from './FilterBrand';
import FilterCategory from './FilterCategory';

export const FilterContainer = ({ shops }) => {
  const _loading = useSelector(({ loading }) => loading.loading);
  const { category0, category1 } = useSelector(({ products }) => products);
  const { currentRefinementBrand } = useBrandHeroes();
  const tmpCat1 = category1 && JSON.parse(category1).label.substr(JSON.parse(category1).label.indexOf('>') + 2);
  const tmpCat0 = category0 && JSON.parse(category0).label;
  const tmpBrand = currentRefinementBrand && JSON.parse(currentRefinementBrand).id;

  const tmpShops = shops?.map(el => `${el.name}${el.adrCode ? `||${el.adrCode}` : ''}`);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinementBrand, category0, category1, shops]);
  const dispatch = useDispatch();

  return (
    <div>
      {_loading && (
        <div style={{ position: 'absolute', left: '30%', top: '10%', zIndex: '99' }}>
          <LoaderComponent />
        </div>
      )}
      {_loading && (
        <div style={{ position: 'absolute', background: 'transparent', height: '100%', width: '100%', zIndex: '98' }} />
      )}
      <div className="d-flex flex-column justify-content-center" style={_loading ? { filter: 'blur(4px)' } : undefined}>
        <CustomClearRefinementsBtutton />
        <div className="border-bottom pb-4 mb-4" />
        <FilterBrand />
        <div className="border-bottom pb-4 mb-4" />
        <FilterCategory />
        <div className="border-bottom pb-4 mb-4" />
        <CustomClearRefinementsBtutton />

        <button
          className="btn btn-soft-dark my-2"
          type="button"
          onClick={() => dispatch(exportCSV(tmpBrand, category1 ? tmpCat1 : tmpCat0, tmpShops))}
        >
          Télécharger le catalogue selon le filtre en excel
        </button>
      </div>
    </div>
  );
};

FilterContainer.propTypes = {
  shops: PropTypes.array,
};
export default FilterContainer;
