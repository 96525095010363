import { discount } from './discount';
import { priceNoZeros } from './priceNoZeros';
import { totalOfCarts } from './totalOfCarts';
import { trimSpacesFromObject } from './trimSpacesFromObject';

export const getTtcPrice = (cards, tvaList, loggedUser) => {
  const totalTVA = {
    '0': 0,
    '7': 0,
    '13': 0,
    '19': 0,
  };

  // eslint-disable-next-line no-unused-expressions
  cards?.forEach(card => {
    const TVA = tvaList
      .filter(data => trimSpacesFromObject(data.tvaTie) === trimSpacesFromObject(loggedUser.TVATIE))
      .filter(data => trimSpacesFromObject(data?.tvaArt) === trimSpacesFromObject(card?.product?.tvaart));

    if (discount(card.product.articlesDiscount) !== 0) {
      totalTVA[TVA[0].tvaP] +=
        ((card?.product?.price - (card?.product?.price * discount(card.product.articlesDiscount)) / 100) *
          TVA[0].tvaP) /
        100;
    } else {
      totalTVA[TVA[0].tvaP] += (card?.product?.price * TVA[0].tvaP) / 100;
    }
  });

  if (totalTVA[0] > 0) return priceNoZeros(totalOfCarts(cards) + totalTVA[0], 'TND');
  if (totalTVA[7] > 0) return priceNoZeros(totalOfCarts(cards) + totalTVA[7], 'TND');
  if (totalTVA[13] > 0) return priceNoZeros(totalOfCarts(cards) + totalTVA[13], 'TND');
  if (totalTVA[19] > 0) return priceNoZeros(totalOfCarts(cards) + totalTVA[19], 'TND');

  return 0;
};
