import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logout } from '_redux/actions/auth';
import { useDispatch } from 'react-redux';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import Modal from 'components/Modal';
import { ModalBody } from 'reactstrap';
import UpdatePassword from 'pages/UpdatePassword';

const HeaderTopBar = ({ isLoggedIn, user }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const [openPass, setOpenPass] = useState(false);

  const _carts = user?.user?.user?.carts;
  const length = number => {
    return number.toString().length;
  };

  return (
    <>
      {isLoggedIn && (
        <>
          <li className="list-inline-item">
            <Link
              style={{ borderRadius: 5 }}
              title="panier"
              to="/card"
              className="js-hs-unfold-invoker btn-icon btn-ghost-secondary"
            >
              <i className="fas fa-shopping-cart" />
              {numberItemInCarts(_carts) > 0 && (
                <sup
                  style={{
                    width: length(numberItemInCarts(_carts)) + 22,
                    height: length(numberItemInCarts(_carts)) + 22,
                  }}
                  className="avatar-status avatar-primary d-flex justify-content-center"
                >
                  {numberItemInCarts(_carts)}
                </sup>
              )}
            </Link>
          </li>
          <li className="list-inline-item">
            <button
              type="button"
              style={{ borderRadius: 5, borderWidth: 0 }}
              title="déconnection"
              onClick={() => setOpenPass(!openPass)}
              className="btn-icon btn-ghost-secondary"
            >
              <i className="fa fa-user-circle" />
            </button>
          </li>
          <li className="list-inline-item">
            <button
              type="button"
              style={{ borderRadius: 5, borderWidth: 0 }}
              title="déconnection"
              onClick={handleLogout}
              className="btn-icon btn-ghost-secondary"
            >
              <i className="fa fa-power-off fa-hand-pointer" />
            </button>
          </li>
          <Modal
            className="myModal"
            isOpen={openPass}
            closeModal={() => setOpenPass(false)}
            title={<span>Modifier votre mote de passe</span>}
          >
            <ModalBody>
              <UpdatePassword setOpenPass={setOpenPass} openPass={openPass} />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

HeaderTopBar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default HeaderTopBar;
