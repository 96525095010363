import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Chip, Tooltip } from '@material-ui/core';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { isEntExpired } from 'helpers/isEntExpired';
import FacturePaymentDot from './FacturePaymentDot';

const FactureItem = ({ facture }) => {
  return (
    facture && (
      <div key={facture?.id} className="card card-frame py-3 px-4 mb-2">
        <div className="row my-auto">
          <Link className="col-3 text-dark text-justify my-auto" to={`/facture/${facture?.id}`}>
            <p className="my-auto text-left ml-3">{facture?.pino}</p>
          </Link>
          <Link className="col-2 text-left my-auto" to={`/facture/${facture?.id}`}>
            <p className="my-auto"> 
              {' '}
              {moment(facture?.creation).format('DD/MM/YYYY')}
            </p>
          </Link>

          <span className="col-3 d-flex justify-content-start my-auto">
            <div className="container testimonial-group scroll my-auto">
              <div className="row my-auto">
                {isEntExpired(trimSpacesFromObject(facture?.refPino)) !== '' ? (
                  <div className="pb-2 my-auto">
                    <Chip
                      label={isEntExpired(trimSpacesFromObject(facture?.refPino))}
                      variant="default"
                      style={{ color: 'red', fontWeight: 'bold' }}
                    />
                  </div>
                ) : (
                  <>
                    {facture?.BlList.map((bl, index) => {
                      return (
                        <Link key={bl?.BLNO} className="item pl-1 pb-2 my-auto" to={`/bon-de-livraison/${bl?.BLNO}`}>
                          <Chip
                            key={index.id}
                            label={trimSpacesFromObject(bl?.PREFBLNO + bl?.BLNO)}
                            variant="default"
                            style={{ color: '#940c64' }}
                          />
                        </Link>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </span>
          <Link className="col-3 d-flex justify-content-start my-auto" to={`/facture/${facture?.id}`}>
            {`${facture.ttcmt} TND`}
            <i className="fas fa-angle-right fa-sm ml-1 pt-1" />
          </Link>
          <FacturePaymentDot facture={facture} Tooltip={Tooltip} />
        </div>
      </div>
    )
  );
};

FactureItem.propTypes = {
  facture: PropTypes.object.isRequired,
};
export default FactureItem;
