import _ from 'lodash';

export const checkFacturePayment = (payments, factureTtc) => {
  let etat = [];

  let percent = 0;

  const types = {
    E: 'a été payée en espèces',
    W: 'a été payée par une méthode de paiement inconnue',
    T: 'a été payée avec un traite',
    R: 'a été retenue',
    C: 'a été payée par chèque',
    V: 'a été payée avec un virement bancaire',
  };

  if (payments?.length === 0) return { percent, etats: etat };

  payments &&
    payments.forEach(e => {
      if (factureTtc - e?.MTR3 < 1.9) percent = 100;
      etat.push({
        price: e?.MTR3,
        paymentType: types[e?.ETAT?.[0]],
        numCheque: e?.numCheque,
        transaction: e?.TRANSAC,
      });
    });
  let somme = 0;

  etat = etat.filter(
    (elem, index, list) => index === list.findIndex(t => t.transaction === elem.transaction && t.price === elem.price),
  );

  if (percent !== 100) {
    etat.forEach(e => {
      somme += e?.price;
    });
    percent = (somme / factureTtc) * 100;
  } else {
    etat.forEach(e => {
      if (factureTtc - e?.price < 100)
        etat = _.remove(etat, n => {
          return factureTtc - n?.price < 1;
        });
    });
  }

  percent = percent.toFixed(3);

  if (percent > 100) percent = 100;

  return { percent, etats: etat };
};
