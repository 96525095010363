import PropTypes from 'prop-types';
import React from 'react';

const SafeAreaView = ({ children }) => {
  return <div>{children}</div>;
};

SafeAreaView.propTypes = {
  children: PropTypes.node,
};
export default SafeAreaView;
