/* eslint-disable react-hooks/exhaustive-deps */
import useElastic from 'hooks/useElastic';
import React from 'react';

const SearchBar = () => {
  const { refineSearch, query } = useElastic();
  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="input-group input-group-sm">
      <div className="input-group-prepend bg-transparent">
        <div className="input-group-text bg-transparent">
          <i className="fa fa-search bg-transparent" />
        </div>
      </div>
      <input
        type="text"
        className="form-control"
        value={query}
        onChange={event => refineSearch(event.target.value)}
        placeholder="Cherchez votre produit ici ..."
      />
    </form>
  );
};

SearchBar.propTypes = {};

export default SearchBar;
