import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BCItem from 'components/BonCommande/BCItem';
import SearchBannerEnt from 'components/SearchBannerEnt';
import moment from 'moment';

const ListBC = ({ BL }) => {
  const [sortedFacturesData, setSortedFacturesData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterByType, setFilterByType] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (!BL?.length) return;
    const searchRegex = searchValue && new RegExp(`${searchValue}`, 'gi');

    const result = BL.filter(
      ent =>
        (!searchRegex || searchRegex.test(ent.pino)) &&
        (!filterByType || ent.etat === filterByType) &&
        ((!date.endDate && !date.startDate) ||
          moment(ent.creation).isBetween(date.startDate, date.endDate, 'days', '[]')),
    );

    setSortedFacturesData(result);
  }, [searchValue, BL, filterByType, date]);

  return (
    <div className="col-12">
      <h1 className="pb-5">Liste des bons de commande</h1>
      <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-7">
        <SearchBannerEnt
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterByType={filterByType}
          setFilterByType={setFilterByType}
          {...{ setDate, date, setFocusedInput, focusedInput }}
        />
      </div>
      <div className="row mb-2 mr-3">
        <span className="col-4 text-left">No de BC</span>
        <span className="col-4 text center">Date</span>
        <span className="col-4 text-right">Montant (TTC)</span>
      </div>
      <div>
        {sortedFacturesData &&
          sortedFacturesData?.map((BLitem, index) => {
            return <BCItem key={`${index + 1}`} BL={BLitem} />;
          })}
      </div>
    </div>
  );
};

ListBC.propTypes = {
  BL: PropTypes.array,
};

export default ListBC;
