import { discount } from './discount';

export const totalOfCarts = carts => {
  let sumPrice = 0;

  if (!carts) return 0;
  carts.forEach(product => {
    if (discount(product.product.articlesDiscount) !== 0) {
      sumPrice +=
        (product.product.price - (product.product.price * discount(product.product.articlesDiscount)) / 100) *
        product.quantity;
    } else {
      sumPrice += product.product.price * product.quantity;
    }
  });

  return sumPrice;
};
