/* eslint-disable max-len */
import React from 'react';
import SEO from 'components/SEO';
import { useDispatch } from 'react-redux';
import { login } from '_redux/actions/auth';
import { useHistory } from 'react-router-dom';
import LoginForm from 'containers/LoginForm';

const Login = () => {
  const history = useHistory();
  const disptach = useDispatch();

  const handleSubmit = values => {
    disptach(login(values, history));
  };

  return (
    <>
      <SEO title="Login page" description="Se connecter à MB-Distribution" />
      <main id="content" role="main">
        <div className="d-flex justify-content-center pt-5">
          <LoginForm onSubmit={handleSubmit} />
        </div>
      </main>
    </>
  );
};

export default Login;
// export default Login;
